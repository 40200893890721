// panel

.content-container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  align-items: center;

  &__view {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 820px;
  }

  &__head {
    &__title {
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 35px;
      color: $text-gray;
      margin: 0;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $text-gray;
      margin: 0;
    }
  }

  @media (max-width:600px) {
    padding: 8px 16px;

    &__head {
      margin: 16px 0 24px 16px;

      &__title {
        font-size: 18px;
        line-height: 25px;
        font-weight: 800;
        color: $text-dark-gray;
      }

      &__description {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 8px;
      }
    }
  }
}

.panel {
  padding: 32px;

  @media (max-width: 600px) {
    padding: 0;
    background: $secondary-color;
    height: 25%;
  }

  @media (max-width: 420px) {
    height: 30%;
  }

  &__content {
    &__back {
      padding-left: 15px;
      text-transform: uppercase;
      font-size: 8pt;
      font-weight: bold;
      color: $secondary-color;

      &:hover {
        color: $secondary-color-dark;
        transition: all 0.2s ease-in;
      }
    }

    &--border {

      @media (max-width: 600px) {
        border-left: none;
      }
    }
  }

  &__title {
    font-size: 28px;
    color: #313234;
    margin-left: -5px;
    font-weight: bold;
  }

  &__bottom {
    &__title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin: 20px 0;
      color: $primary-color;
      font-size: 24px;
      font-weight: bold;

      @media (max-width: 600px) {
        margin: 10px;
        font-size: 21px;
      }
    }
  }

  &__container {
    width: 820px;
    margin: auto;
    padding: 15px 0;

    &--no-padding {
      padding: 25px 0 !important;
    }

    // Small devices (landscape phones, 576px and up)
    @media (max-width: 576px) {
      // add
      width: auto;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      // add
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      // add
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      // add
    }

    @media (max-width: 600px) {
      padding-bottom: 0 !important;
      padding-top: 7px !important;
    }
  }

  &__card {
    padding: 15px;

    @media (max-width: 600px) {
      zoom: 0.8;
      padding: 0;
    }

    &__visibility {
      position: absolute;
      right: 30px;
      top: 30px;
      fill: #bebebe;
      cursor: pointer;
      z-index: 1;

      &:hover {
        fill: $secondary-color;
        transition: all ease-in 0.2s;
      }
    }

    &__header {
      border-bottom: 2px dashed $dashed-gray;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__bottom {
      border-bottom: 2px dashed $dashed-gray;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__value {
      &__title {
        font-size: 28pt;
        font-weight: bold;
        margin: 0;
        color: $primary-color;
      }

      &__info {
        font-size: 12pt;
        font-weight: bold;
        color: $primary-color-darker;
        text-align: center;
      }

      &__invisible {
        position: relative;

        &__question {
          width: 15px;
          height: 15px;
          cursor: pointer;
          margin-bottom: 3px;
        }

        &__info {
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          width: 280px;
          background: #000000;
          padding: 15px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          transition: all ease-in 0.2s;
          visibility: hidden;
          position: absolute;
          bottom: calc(50% + 15px);
          left: 50%;
          transform: translateX(-50%);
          color: #FFFFFF;
          text-align: left;

          &:before {
            content: '';
            border-style: solid;
            border-color: #000000 transparent;
            border-width: 6px 6px 0 6px;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
          }

          &__text {
            &__two {
              font-size: 12px;
              margin-top: 5px;
            }
          }


        }

        &:hover &__info {
          opacity: 1;
          visibility: visible;
          z-index: 1000;
        }

      }

      &--hidden {
        font-size: 10pt;
        font-weight: bold;
        color: $primary-color;
        text-transform: uppercase;
        letter-spacing: 2pt;
        margin: 10px 0 5px;
      }

      &--not-positive {
        font-weight: bold;
        color: $primary-color;
        margin: 10px 0 5px;
      }

      &--positive {
        &__info {
          font-size: 10pt;
          font-weight: bold;
          color: $primary-color;
          text-transform: uppercase;
          letter-spacing: 2pt;
        }

        &__value {
          font-weight: bold;
          color: $green;
          margin: 10px 0 5px;

          &__inner {
            &__decimal {}
          }
        }
      }
    }

    &__action {
      padding-top: 25px;
      margin-top: 15px;
      border-top: 1px solid $gray-border;

      &__button {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        cursor: pointer;

        &--with-border-right:after {
          content: '';
          width: 1px;
          height: 40px;
          position: absolute;
          top: 0;
          right: -15px;
          background-color: $gray-border;
        }

        &--disabled {
          filter: grayscale(1);
          cursor: default;
          pointer-events: none;
        }

        &__value {
          &__inner {
            font-size: 8pt;
            line-height: 15pt;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 4px;
            color: $primary-color;
            display: block;
            padding-left: 15px;

            @media (max-width: 600px) {
              padding-left: 5px;
            }

            &--disabled {
              color: #bebebe;
            }
          }
        }

        &:not(&--disabled):hover {
          .panel__card__action__button__value__inner {
            color: lighten($primary-color, 15);
            transition: all 0.2s ease-in;
          }
        }

        &__icon {
          &--right {
            @media (max-width: 600px) {
              margin-right: 10px;
            }
          }

          &--disabled {
            svg {
              fill: #bebebe;
              color: #bebebe;
            }
          }
        }
      }
    }

    &__name {
      font-size: 20pt;
      font-weight: bold;
      color: $primary-color-darker;
      margin-bottom: 5px;
    }

    &__description {
      font-size: 12pt;
      color: $paragraph-color;
    }

    &__validate-button {
      font-size: 10pt;
      letter-spacing: 3pt;
      font-weight: bold;
      color: $primary-color-darker;
      display: inline-block;
      margin-top: 24px;
      cursor: pointer;

      &:hover {
        color: lighten($primary-color, 15);
        transition: all 0.2s ease-in;
      }
    }
  }
}

.btn {
  &__link {
    background: none;
    border: none;
    cursor: pointer;
    color: $secondary-color;
    font-size: 11px;
    letter-spacing: 3.3px;
    font-weight: bold;

    &:hover {
      color: $secondary-color-dark;
      transition: all 0.2s ease-in;
    }
  }
}

.link-no-style {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.-primary {
  color: $primary-color;
}

.mobile {
  @media (min-width: 600px) {
    display: none !important;
  }

  &--pr {
    @media (max-width: 600px) {
      padding-right: 0 !important;
    }
  }

  &--pl {
    @media (max-width: 600px) {
      padding-left: 0 !important;
    }
  }
}

.mobile-hidden {
  @media (max-width: 600px) {
    display: none !important;
  }
}