.credit-card {
    background: $background-secondary;
    border-radius: 8px;
    width: 100%;
    padding: 32px;
    scroll-snap-align: start;
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__flag {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        &__icon {
            width: 21px;
            cursor: pointer;
        }
    }

    &__data {
        margin-top: 24px;
        color: $text-dark-gray;

        h4 {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin: 0;
        }
    }

    @media (min-width:600px) {
        hr {
            display: none;
        }

        &__mobile-icon {
            display: none;
        }
    }

    @media (max-width:600px) {
        display: flex;
        align-items: center;
        background: transparent;
        justify-content: space-between;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__icon {
                display: none;
            }
        }

        &__data {
            flex: 1;
            justify-content: flex-start;
            margin-left: 16px;
            margin-top: 0;
        }

        &__mobile-icon {
            width: 21px;
        }

        &__mobile-divider {
            width: 100%;
            color: $white2;
            position: absolute;
            bottom: -16px;
            left: 0;
        }

        &:last-child {
            hr {
                display: none;
            }
        }
    }
}