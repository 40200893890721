.main-panel {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;

    overflow-x: hidden;

    overflow-y: auto;

    &__container {
        display: flex;
        align-items: flex-start;
        height: calc(100vh - 75px);
    }

    &__content {
        width: 100%;
        height: 100%;

        &__item {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width:600px) {
        overflow-y: auto;
    }
}