.addAccount-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  padding: 32px;
  background: $background;
  border-radius: 16px;
  text-align: left;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    div {
      flex: 1;

      h4 {
        font-weight: 800;
        font-size: 18px;
        line-height: 23px;
        color: $text-dark-gray;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $text-gray;
      }
    }

    &__close {
      cursor: pointer;
    }
  }

  &__form {
    &__row {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 16px;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
    gap: 24px;

    button {
      border: none;
      text-align: center;
      cursor: pointer;
    }

    button:first-of-type {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $text-gray;
      background: transparent;

      &:hover {
        color: $text-dark-gray;
      }
    }

    button:last-of-type {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $white3;

      background: $primary;
      border-radius: 8px;
      padding: 16px;
      text-transform: uppercase;

      &:hover {
        background: $primary-hover;
      }
    }
  }
}

// .account {
//   &__title {
//     color: $primary-color-darker;
//     font-weight: bold;
//     font-size: 14pt;
//     margin: 7px 0 25px;
//   }

//   &__input {
//     padding: 0px 50px 0px;

//     &__left {
//       padding-right: 15px;
//     }

//     &__right {
//       padding-left: 15px;
//     }

//     &__inner {
//       border: none;
//       border-radius: 22px;
//       box-shadow: 0px 10px 18px #0000001a;
//       height: 40px;
//       margin: 10px 0;
//       width: 100%;
//       padding: 5px 12px;
//     }

//     &__composed {
//       background-color: #fff;

//       &__number {
//         border: none;
//         width: 110px;

//         @media (max-width: 700px) {
//           width: 95px;
//         }

//         @media (max-width: 360px) {
//           width: 75px;
//         }

//         @media (max-width: 340px) {
//           width: 60px;
//         }
//       }

//       &__digit {
//         border: none;
//         width: 60px;

//         @media (max-width: 400px) {
//           width: 50px;
//         }
//       }
//     }

//     &__group {
//       margin-top: 10px;
//       padding: 5px 20px;
//       border-radius: 22px;
//       background-color: #fff;
//       height: 40px;
//       display: flex;
//       width: 195px;
//       box-shadow: 0px 10px 18px #0000001a;

//       @media (max-width: 400px) {
//         width: 170px;
//       }

//       @media (max-width: 360px) {
//         width: 160px;
//       }

//       @media (max-width: 340px) {
//         width: 140px;
//       }
//     }
//   }

//   &__dropdown {
//     margin: 5px 0;
//     padding: 0 40px;

//     &__others {
//       border: none;
//       margin: 0px 23px;
//       color: $secondary-color;
//       text-align: center;
//       background: #f5f5f5;
//       font-size: 14px;
//     }
//   }

//   &__buttons {
//     margin: 20px 0;
//   }
// }

// input:disabled {
//   background: #f7f5f6;
//   -webkit-appearance: none;
// }

// .btn {
//   &__cancel {
//     border: none;
//     background: #f5f5f5;
//     color: $primary;
//     cursor: pointer;
//     padding: 5px;
//     margin: 15px 30px;

//     &:hover {
//       color: $primary-hover;
//       transition: all 0.2s ease-in;
//     }
//   }

//   &__submit {
//     letter-spacing: 3.3px;
//     height: 40px;
//     padding: 10px 20px;
//     text-transform: uppercase;
//     border-radius: 22px;
//     border: none;
//     font-size: 11pt;
//     color: #fff;
//     box-shadow: 0px 10px 18px #0000001a;
//     cursor: pointer;
//     background: $primary;

//     &:hover {
//       background: $primary-hover;
//       transition: all ease-in 0.2s;
//     }
//   }
// }