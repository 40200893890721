.extract {
  svg {
    fill: $secondary-color;
  }

  &__item {
    &__wrapper {
      cursor: pointer;
      border: 1px solid transparent;
      border-bottom: 1px solid #dcdcdc;
      padding: 10px 5px;

      &:hover {
        border-radius: 3px;
        box-shadow: 0 0 5px #a7cae8;
        border: 1px solid #a7cae8;
        transition: all 0.2s ease-in;
      }
    }
  }
}

.text {
  font-size: 16px;
  color: $primary-color-darker;

  &__operation {
    font-weight: bold;
  }

  &-yellow {
    color: $yellow;
  }

  &-light-blue {
    color: $light-blue;
  }

  &-red {
    color: $red;
  }

  &-green {
    color: $green;
  }
}