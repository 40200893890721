.deposit {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    background: #fff;
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
    border-radius: 8px;
    padding: 32px;
    width: 100%;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $dark-gray;
    }
  }

  &__content {
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #313234;
      margin: 0;
    }

    &__description {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #66696F;
    }
  }

  &__arrow {
    cursor: pointer;
    color: $dark-gray;
  }

  @media (max-width: 600px) {
    &__wrapper {
      box-shadow: none;
      border-radius: 0;
      border-top: 1px solid $white2;
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-bottom: 0;
      margin-top: 0;
    }

    &__content {
      max-width: 90%;

      &__title {
        color: $primary;
      }
    }

    &__arrow {
      color: $primary-color;
    }
  }
}