 .remove-modal {
   display: flex;
   flex-direction: column;
   align-items: center;
   align-self: center;
   max-width: 400px;
   padding: 32px;
   border-radius: 16px;
   background: $background;

   h3 {
     font-weight: 800;
     font-size: 18px;
     line-height: 23px;
     text-align: center;
     color: $text-dark-gray;
   }

   p {
     font-weight: 400;
     font-size: 16px;
     line-height: 22px;
     text-align: center;
     color: $text-gray;
   }

   button {
     cursor: pointer;
     border: none;

     &:first-of-type {
       background: $primary;
       box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
       border-radius: 8px;
       color: $white3;
       padding: 12px 16px;
       font-weight: 700;

       &:hover {
         background: $primary-hover;
       }
     }

     &:last-of-type {
       background: transparent;
       font-weight: 400;
       font-size: 16px;
       line-height: 22px;
       color: $text-gray;
       margin-top: 16px;

       &:hover {
         color: $text-dark-gray;
       }
     }
   }
 }

 .modal {
   &__title {
     display: flex;
     justify-content: center;
     font-weight: bold;
     margin-top: 15px;
     border-bottom: 1px solid #c9c9c9;
     margin-left: 50px;
     max-width: 280px;
     padding-bottom: 3px;

     @media (max-width: 414px) {
       margin-left: 80px;
     }

     @media (max-width: 375px) {
       margin-left: 60px;
     }

     @media (max-width: 320px) {
       margin-left: 40px;
     }
   }

   &__subtitle {
     text-align: center;
     padding: 0px 60px;
     margin-top: 40px;
     margin-bottom: 40px;
   }

   &__button {
     &__cancel {
       margin-left: 50px;
       border: none;
       background: #fff;
       cursor: pointer;

       &:hover {
         font-weight: bold;
       }
     }

     &__confirm {
       margin-left: 80px;
       color: white;
       background: $secondary-color;
       border: none;
       width: 60px;
       height: 30px;
       border-radius: 4px;
       cursor: pointer;

       @media (max-width: 414px) {
         margin-left: 120px;
       }

       @media (max-width: 320px) {
         margin-left: 60px;
       }

       &:hover {
         background: $secondary-color-dark;
       }
     }
   }
 }