.cashout-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 32px;

    &__title {
        text-align: left;
        font-weight: 800;
        font-size: 26px;
        line-height: 33px;
        color: $text-gray;
        margin: 0 !important;
    }

    &__operation-time {
        color: $green!important;
        font-weight: 400;
        font-size: 16px!important;
        margin-top: 8px!important;
        margin-bottom: 0;

        i {
            margin-right: 8px;
            font-size: 16px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: $radius;
        border: 1px solid $border;
        width: 100%;
        padding: 32px;
        margin-top: 24px;

        &__icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: $green;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 20px;
                color: $white;
            }
        }

        &__title {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin: 24px 0;
            color: $text-dark-gray;
        }

        &__divider {
            width: 100%;
            border: 1px dashed $border;
            width: calc(100% + 64px);
        }

        &__information-id {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            p,
            span {
                margin: 0;
                font-size: 16px;
                line-height: 22px;
            }

            p {
                font-weight: 400;
                color: $text-gray;
            }

            span {
                font-weight: 600;
                color: $text-dark-gray;
            }
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__information {
            margin-top: 16px;

            p,
            span {
                margin: 0;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $text-gray;
            }

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }

            &__left {
                text-align: left;
                align-self: flex-start;
            }

            &__right {
                text-align: right;
                align-self: flex-end;
            }
        }
    }
}