.map {
  &__list {
    width: 25vw;

    @media (max-width: 1024px) {
      width: 100%;
      padding: 30px;
    }

    &__title {
      margin-bottom: 30px;
      font-weight: bold;
      color: $primary-color;
      font-size: 1.2rem;

      @media (max-width: 1024px) {
        margin-bottom: 15px;
      }
    }

    &__single {
      display: block;
    }
  }
}

.institutional {
  &__map {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7vw;
    margin: 0 40px;

    @media (max-width: 1024px) {
      margin: 0;
      padding: 0;
      flex-direction: column;
      margin-bottom: 50px;
    }
  }
}
