.balance_card {
  display: flex;
  flex-direction: column;
  width: 472px;
  border-radius: 8px;
  padding: 32px;
  border: 1px solid #ebeced;
  margin-bottom: 24px;

  &__mobile {
    &__header {
      display: none;
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__text {
      text-align: center;
      margin-top: 16px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__container {
      &__title {
        font-size: 16px;
        line-height: 16px;
        color: #66696f;
        font-weight: 600;
      }

      &__balance {
        font-size: 20px;
        line-height: 28px;
        color: #313234;
        font-weight: 700;
      }
    }

    &__icon {
      cursor: pointer;

      svg {
        color: #313234;
        margin-left: 16px;
      }
    }
  }

  &__divider {
    width: 100%;
    border: 1px dashed #ebeced;
    margin-top: 16px;
  }

  &__button {
    background: transparent;
    border: none;
    outline: none;

    display: flex;
    align-self: flex-end;
    align-items: center;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #BE00FF;

    cursor: pointer;

    svg {
      color: #BE00FF;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    border: none;
    padding: 0 16px;

    &__header {
      display: none;
    }

    &__divider {
      margin-top: 8px;
    }

    &__mobile {
      &__header {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-items: center;
        grid-gap: 24px;
        margin-top: 24px;

        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 8px;
          width: 60px;
          height: 60px;
          box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
          color: #313234;
        }

        &__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #313234;
          text-align: center;
          margin-top: 8px;
        }

        &__button {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #313234;
        margin-top: 24px;
      }
    }
  }
}