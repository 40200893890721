.panel {
  &__faq {
    display: block;
    min-height: calc(100vh - 130px);
    position: relative;

    &__footer {
      position: relative;
      bottom: 0;
      width: 100%;
    }
  }
}
