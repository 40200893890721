.animated-card {
  background: transparent;
  width: 400px;
  height: 205px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    animation: flashflip 0.01s;
  }

  &--front,
  &--back {
    font-family: courier;
    color: #fff;
    padding: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #28334a;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2328334a'/%3E%3Cstop offset='1' stop-color='%23006699'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%234197e3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%234197e3' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%234197e3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%234197e3' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    border-radius: 7px;
    box-shadow: 0px 10px 18px 10px #0000001a;
    backface-visibility: hidden;
  }

  &--back {
    padding: 0;
    transform: rotateY(-180deg);
  }

  &__bottom {
    padding-top: 10px;

    &__number {
      display: flex;
      align-items: center;
      justify-content: start;
      color: #fff;
      font-size: 20pt;
      font-weight: bold;

      @media (max-width: 600px) {
        font-size: 15pt;
      }
    }

    &__holder {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 3px 0 5px;
      font-size: 11pt;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 9pt;
      }
    }

    &__valid {
      font-size: 9pt;

      @media (max-width: 600px) {
        font-size: 9pt;
      }
    }
  }

  &__cvv {
    margin: 40px 20px 0;
    display: flex;
    align-items: center;
    height: 40px;
    width: 60%;
    color: #000;
    background: #fff;

    &__content {
      font-weight: bold;
      font-style: italic;
      width: 100%;
    }
  }

  &__reader {
    margin-top: 20px;
    display: flex;
    align-items: center;
    height: 40px;
    background: rgba(226, 226, 226, 1);
    background: -moz-linear-gradient(
      top,
      rgba(226, 226, 226, 1) 0%,
      rgba(186, 184, 186, 1) 4%,
      rgba(190, 189, 190, 1) 22%,
      rgba(209, 209, 209, 1) 99%,
      rgba(254, 254, 254, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(226, 226, 226, 1)),
      color-stop(4%, rgba(186, 184, 186, 1)),
      color-stop(22%, rgba(190, 189, 190, 1)),
      color-stop(99%, rgba(209, 209, 209, 1)),
      color-stop(100%, rgba(254, 254, 254, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(226, 226, 226, 1) 0%,
      rgba(186, 184, 186, 1) 4%,
      rgba(190, 189, 190, 1) 22%,
      rgba(209, 209, 209, 1) 99%,
      rgba(254, 254, 254, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(226, 226, 226, 1) 0%,
      rgba(186, 184, 186, 1) 4%,
      rgba(190, 189, 190, 1) 22%,
      rgba(209, 209, 209, 1) 99%,
      rgba(254, 254, 254, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(226, 226, 226, 1) 0%,
      rgba(186, 184, 186, 1) 4%,
      rgba(190, 189, 190, 1) 22%,
      rgba(209, 209, 209, 1) 99%,
      rgba(254, 254, 254, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(226, 226, 226, 1) 0%,
      rgba(186, 184, 186, 1) 4%,
      rgba(190, 189, 190, 1) 22%,
      rgba(209, 209, 209, 1) 99%,
      rgba(254, 254, 254, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0 );
  }
}

.flip {
  .animated-card__inner {
    transform: rotateY(180deg);
  }
}

@keyframes flashflip {
  0% {
    opacity: 0;
    -webkit-transform: rotatey(-180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotatey(180deg);
  }
}
