.faq {
  &__back {
    border: none;
    background: none;
    display: flex;
    color: #475665;
    padding: 5px;
    margin-bottom: 15px;
    align-items: center;
    font-size: 0.85rem;

    svg {
      fill: #475665;
      margin-right: 15px;
    }
  }

  &__fee {
    width: 60%;

    @media (max-width: 1024px) {
      width: 100%;
      padding: 15px;
      padding-bottom: 100px;
    }

    &__container {
      padding: 10px;
      background-color: #fff;

      &__footer {
        position: absolute;
        margin-top: -1rem;
      }
    }

    &__subtitle {
      padding-bottom: 10px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.4rem;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 20px;
      width: 100%;
      left: -5px;

      svg {
        fill: $primary-color;
      }

      &__icon {
        cursor: pointer;
        margin-right: 15px;

        &:hover {
          fill: $primary-color;
          transition: ease-in 0.2s;
        }
      }
    }

    &__table {
      border: solid 3px #ddd;

      &__td {
        border: solid 3px #ddd !important;
        color: #999;
        font-weight: bold;
      }

      &__th {
        border: none !important;
      }

      &__header {
        border: 3px solid $primary-color;
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  &__doubt {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    color: $primary-color;

    &__title {
      font-weight: bold;
      font-size: 12pt;
    }

    &__container {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-top: 30px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #f6f6f6;
        border-radius: 0%;
        border: solid 1px #4c5871;
      }

      &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
        width: 50px;
        height: 50px;
        left: -5px;
        top: -1px;
      }

      &__input:checked~&__checkmark {
        background-color: $secondary-color;
      }

      &__checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      &__input:checked~&__checkmark:after {
        display: block;
      }

      &__checkmark:after {
        top: -2px;
        left: 2px;
        width: 2px;
        height: 2px;
        content: '\2713';
        color: #fff;
      }
    }
  }

  &__footer {
    justify-content: flex-end;
    margin-bottom: 80px;

    @media (max-width: 1024px) {
      margin-top: -80px;
    }

    &__doubt {
      display: flex;
      flex-direction: column;
      margin-top: -50px;
      margin-bottom: 80px;

      @media (min-width: 1024px) {
        margin-top: 20px;
      }

      &__back {
        display: flex;
        justify-content: center;
        font-size: 12pt;
      }

      &__description {
        display: flex;
        font-size: 12pt;
        justify-content: center;
        margin-bottom: 14px;
      }
    }

    &__title {
      color: $primary-color;
      margin: 30px 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__list {
      margin: 20px 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      &__single {
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          &:hover {
            svg {
              cursor: pointer;
              fill: $primary-color;
              transition: ease-in 0.2s;
            }
          }
        }

        &__text {
          cursor: pointer;
          color: $secondary-color;
          font-size: 0.8rem;
          padding-left: 10px;

          &:hover {
            color: $primary-color;
            transition: ease-in 0.2s;
          }
        }
      }
    }
  }

  &__topic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.4rem;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 20px;
      width: 100%;
      left: -5px;

      &__icon {
        cursor: pointer;
        margin-right: 15px;
        fill: $primary-color;

        &:hover {
          fill: $primary-color;
          transition: ease-in 0.2s;
        }
      }
    }

    &__mosaic {
      display: flex;
      align-items: center;

      &__wrapper {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
      }

      &__action {
        flex-basis: 50%;
        height: 150px;
        max-width: 200px;
        min-width: 200px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.17);
        display: flex;
        align-items: center;
        justify-content: center;

        &--no-border {
          border-bottom: 1px solid transparent;
        }

        &--right-border {
          border-right: 1px solid rgba(112, 112, 112, 0.17);
        }

        &__button {
          outline: none;
          padding: 10px 5px;
          cursor: pointer;
          color: #475665;
          background: none;
          border: 1px solid transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          svg {
            margin-bottom: 10px;
          }

          &:hover {
            color: $primary-color-dark;
            transition: ease-in 0.2s;
          }

          &--selected {
            color: $primary-color-dark;
          }
        }
      }
    }

    &__answer {
      max-width: 25vw;
      padding-top: 65px;

      @media (max-width: 1024px) {
        padding: 15px;
        max-width: 100vw;
      }

      &__description {
        white-space: pre-line;
      }

      &__title {
        font-size: 1.4rem;
        font-weight: bold;
        color: $primary-color;
        margin-bottom: 20px;
      }
    }
  }

  &__topics {
    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      margin-bottom: 50px;

      &__box {
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        width: 370px;
        height: 70px;
        border: 1px solid #dedede;
        border-bottom: 1px solid transparent;
        color: $primary-color;
        background: #fff;

        svg {
          fill: $primary-color;
        }


        @media (max-width: 1024px) {
          height: 100px;
          padding: 15px;
        }

        &__title {
          padding-left: 10px;
          text-align: left;
        }

        &:hover {
          color: #dedede;
          background: $primary-color;
          transition: ease-in 0.2s;

          svg {
            fill: #dedede;
          }
        }

        &:last-of-type {
          border-bottom: 1px solid #dedede;
        }

        &--selected:focus {
          outline: none;
        }

        &--selected {
          color: #fff;
          background: $primary-color-dark;
          outline: none;

          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

.institutional {
  &__faq {
    &__content {
      display: flex;
      justify-content: space-evenly;
      padding: 5vh 0;

      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
}