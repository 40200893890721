.cashin-ticket {
    border: 1px solid #EBECED;
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
    border-radius: 24px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: $green;

            svg {
                color: $white;
            }
        }

        &__title {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: $text-dark-gray;
            margin: 0;
            margin-top: 16px;
        }

        &__value {
            font-weight: 800;
            font-size: 24px;
            line-height: 33px;
            color: $primary-color-darker;
            margin: 0;
            margin-top: 8px;
        }
    }

    &__code {
        color: $text-dark-gray;

        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
        }

        &__value {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__important {
        &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $text-dark-gray;
        }

        &__item {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: $text-gray;

            &:last-child {
                margin: 0;
            }
        }
    }

    &__payment-option {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $text-dark-gray;
    }

    &__action {
        display: flex;

        &__button {
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            color: $primary-color;
            cursor: pointer;

            svg {
                margin-right: 8px;
            }

            &:last-child {
                margin-left: 16px;
            }

            &:hover {
                color: $primary-color-dark;
            }
        }
    }

    &__divider {
        display: flex;
        width: calc(100% + 64px);
        border: 1px dashed $darkest-white;
        margin: 32px 0 32px -32px;
    }

    &__check {
        &__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $text-gray
        }
    }

    &__checklist {
        display: flex;
        align-items: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $text-gray;
        margin: 0 !important;
        line-height: 16px;

        svg {
            color: $primary-color;
            margin-right: 8px;
        }

        p {
            flex: 1;
        }
    }

    @media (max-width:600px) {
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin-top: -16px;
    }
}