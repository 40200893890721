.account-card {
    display: flex;
    flex-direction: column;

    margin-top: 16px;

    &:nth-child(1) {
        margin-top: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
        }

        svg {
            cursor: pointer;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $text-gray;
    }

    &__divider {
        background: $white2;
        width: 100%;
    }
}