.card {
  border-radius: 10px;
  padding: 15px;

  &--configuration {
    max-width: 360px;
    box-shadow: none !important;
    border: 1px solid #dedede !important;

    @media (max-width: 600px) {
      max-width: none;
      box-shadow: 0px -10px 32px #00000024 !important;
      margin-bottom: -5px !important;
      border-radius: 20px 20px 0 0;
    }
  }
}
.no-border {
  border: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
  border: none !important;
}

.d-block {
  width: 80% !important;
  display: block !important;

  @media all and (max-width: 1440px) {
    width: 100% !important;
  }
}

.small-balance {
  width: 85%;
  max-width: 400px;
}

.no-border-radius {
  border-radius: 0 !important;

  @media (max-width: 600px) {
    height: 100vh;
  }
}

.wallet-card {
  @media (max-width: 600px) {
    height: auto !important;
    border-radius: 0 !important;
  }
}

.cash-in {
  @media (max-width: 600px) {
    height: auto !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 5px;
  }
}

.balance-home {
  margin: 0 15px 15px !important;
}

.last-card {
  border-radius: 20px !important;
}

.out-receipt {
  min-width: 520px !important;
}


