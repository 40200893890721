.login {
  background: $opacity-background;

  &__container {
    &__form {
      &__code {
        &--input {
          border: none;
          border-bottom: 1px solid $primary;
          font-size: 12pt;
          letter-spacing: 18px;
          width: 65%;
          padding: 10px;
          padding-bottom: 10px;
          margin-bottom: 15px;
          text-align: center;
        }
      }

      &__small {
        color: $login-small-text;
        margin-bottom: 20px;
        font-size: 8pt;
      }

      &__links {
        font-size: 12pt;
        width: 70%;
        text-align: center;
        margin-bottom: 40px;
        color: #9300C5;

        @media (max-width: 600px) {
          margin-bottom: 20px;
          width: 85%;
          font-size: 11pt;
        }

        &--second-factor {
          width: 100%;

          @media (max-width: 600px) {
            font-size: 11pt;
          }
        }

        &__code {
          border: none;
          background-color: transparent;
          margin: 0;
          display: inline-block;
          outline: 0 !important;
          text-decoration: underline;
          color: $secondary;
        }
      }
    }

    &__title--code {
      font-weight: bold;
      color: $primary;
      font-size: 10pt;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &__highlight--code {
      background-color: $white;
      color: $secondary;
    }

    &__div {
      width: 100%;
      margin-top: 5px;
      margin-left: 80px;

      &__text--code {
        text-align: left;
      }

      @media (max-width: 600px) {
        margin-left: 0;
        width: 85%;
      }
    }

    &__text--card {
      width: 85%;
      text-align: left;
      color: #28334a;
      font-weight: 900;
      margin-top: 38px;
      font-size: 15pt;
      opacity: 1;
    }
  }
}