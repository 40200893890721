.list {
  &__header {
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary-color-darker;
    font-size: 16pt;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 18px;
      justify-content: space-around;
    }
  }

  &__card {
    width: 100%;
    margin-bottom: 70px;

    @media (max-width: 600px) {
      margin-bottom: 20px;
    }

    &--margin-top {
      margin-top: 20px;
    }

    &__wrapper {
      @media (max-width: 600px) {
        padding: 0;
      }
    }

    &__title {
      color: $primary-color-darker;
    }

    &__component {
      margin: 15px 0;
      display: flex;
      flex-wrap: nowrap;
      gap: 5.5vw;
      overflow-x: auto;
      padding: 12px 0px;

      // flex-direction: column;
      // overflow-x: hidden;

      &__account {
        display: flex;
        flex-direction: column;
        padding: 32px;
        @media (max-width: 600px) {
          padding: 20px;
          margin-left: 48px;
          margin-right: 48px;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }

    &__button {
      &__add {
        margin-top: 20px;
        padding: 10px 0;
        color: #313234;
        border: none;
        background: #fff;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;

        @media (max-width: 600px) {
          font-size: 16px;
          margin-top: 0;
        }

        &:hover {
          color: $secondary-color-dark;
          transition: all 0.2s ease-in;
        }
      }

      &__confirm {
        padding: 10px 15px;
        color: #fff;
        text-transform: uppercase;
        border: none;
        background: $secondary-color;
        cursor: pointer;
        font-size: 10pt;
        font-weight: bold;
        letter-spacing: 1px;
        border-radius: 21px;

        &:hover {
          background: $secondary-color-dark;
          transition: all 0.2s ease-in;
        }
      }
    }
  }
}
