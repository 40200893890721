.mobile {
  &__header_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $white;
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: $white;
    padding: 16px;

    svg {
      color: $primary;
    }

    &__shadow {
      box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.03);
    }
  }

  &__header__divider {
    height: 1px;
    display: flex;
    align-self: center;
    width: 95%;
    background: rgba(255, 255, 255, 0.3);
  }

  &__header__balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $secondary;
    padding: 16px;

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $white;
    }

    ;

    &__value {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      height: 28px;
      color: $white;
      margin: 0;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__hidden {
        height: 4px;
        width: 100px;
        background: $white;
        margin-left: 4px;
      }
    }

    ;

    &__description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      height: 20px;
      color: $white;

      display: flex;
      align-items: center;

      &__hidden {
        height: 2px;
        width: 100px;
        background: $white;
        margin-left: 4px;
      }
    }

    &__menu {
      color: $white;
    }

    &__icon {
      margin-left: 16px;
    }
  }
}

// .mobile {
//   &__header {
//     &__link-icon {
//       color: #fff;
//       margin-left: 5px;

//       svg {
//         fill: #fff;
//       }
//     }
//     &__wrapper {
//       padding: 10px 0;
//       background: $secondary-color;
//     }
//     &__icon {
//       fill: $primary-color;

//       &--white {
//         fill: #fff;
//       }
//     }
//   }
// }

// .top-stripe {
//   height: 3vh;
//   background: $primary-color;
// }