.extract {
  &__list {
    padding: 15px;
    display: flex;
    justify-content: center;

    &__container {
      width: 65vw;
      justify-content: center;

      @media (max-width: 600px) {
        width: 85vw;
      }
    }
  }
}
