.mobile__panel__wallet {
  @media (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
}

.page-wrapper {
  @media (max-width: 600px) {
    overflow-x: hidden;
  }
}
