.extract-card {
    display: flex;
    width: 100%;
    margin-top: 16px;


    &__tooltip {
        font-family: 'Plus Jakarta Sans', sans-serif!important;
        max-width: 270px!important;
        font-style: normal!important;
        font-weight: 400!important;
        font-size: 12px!important;
        line-height: 15px!important;
        border-radius: 4px!important;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: #F8F8F8;
        border-radius: 50%;
    }

    &__content {
        flex: 1;
        margin-left: 16px;
        display: flex;
        gap: 16px;

        &__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #313234;
            margin: 0;
        }

        &__description {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #66696F;
            margin: 0;
            margin-top: 4px;
        }

        &__date {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #73757D;
            margin: 0;
            margin-top: 6px;
        }
    }

    &__value {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #66696F;
    }

    @media only screen and (max-width: 600px) {
        #extract-card-desktop {
            display: none;
        }
    }

    @media only screen and (min-width: 600px) {
        #extract-card-mobile {
            display: none;
        }

        &__value {
            margin-top: 4px;
            background: green
        }
    }
}