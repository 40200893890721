.mobile {
  &__sidebar {
    &__section {
      height: 100%;
    }

    &__menu {
      height: 100vh;

      &__last {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 24px;
      }
    }

    &__wrapper {
      height: 100vh;
      background: #e4e4e4;
      position: absolute;
      top: 3vh;
      left: 0;
      z-index: 9999;
      width: 80vw;

      animation-name: sidebar;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
    }

    &__icon {
      fill: $primary;
      color: $primary;
      margin-right: 1rem;
    }

    &__overlay {
      background: $primary-color;
      opacity: 0.8;
      position: absolute;
      top: 3vh;
      right: 0;
      height: 100vh;
      z-index: 9999;
      justify-content: center;
      display: flex;
      align-items: start;
      padding-top: 10px;

      animation-name: overlay;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;

      svg {
        fill: $primary;
      }
    }

    &__user {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
    }

    &__menu {
      zoom: 0.9;
    }
  }
}

@keyframes sidebar {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes overlay {
  0% {
    width: 100vw;
    transoform: translate(-100%, 0);
  }

  100% {
    width: 20vw;
    transform: translate(0, 0);
  }
}