.panel {
  &__history {
    padding-top: 20px;
    padding-bottom: 30px;

    @media (max-width: 600px) {
      padding-top: 0px;
      padding-bottom: 15px;
    }

    &__title {
      margin-bottom: 20px;

      @media (max-width: 600px) {
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }

    &__container {
      width: 75vw;
      margin: auto;

      &__user {
        &__name {
          font-size: 15pt;
          font-weight: bold;
          text-transform: uppercase;
          color: $secondary-color;
        }
      }

      @media (max-width: 600px) {
        width: 85vw;
        margin-top: 10px;
      }
    }

    &__list {
      background: #fff;
    }

    &__filterExtract {
      margin-left: 340px;
      margin-bottom: 5px;
      position: absolute;
      bottom: 91%;

      @media (max-width: 400px) {
        margin-left: 300px;
        bottom: 92%;
      }

      @media (max-width: 350px) {
        margin-left: 250px;
        bottom: 92%;
      }
    }

    &__filterHistory {
      margin-left: 340px;
      margin-top: 10px;
      position: absolute;
      top: 0%;

      @media (max-width: 400px) {
        margin-left: 300px;
      }

      @media (max-width: 350px) {
        margin-left: 250px;
      } 
    }
  }
}

.user_name_wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.filter-icon {
  fill: $primary-color;
}
