.ouvidoria {
  &__wrapper {
    text-align: center;
    color: $primary-color;
    background: #fff;
    border: 1px solid #dedede;
    width: 100%;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 1px 3px #dedede;

    @media (max-width: 600px) {
      padding: 15px;
      background: #f6f6f6;
    }
  }

  &__text {
    margin-bottom: 30px;
  }

  &__form {
    @media (max-width: 600px) {
      padding: 15px;
      background: #f6f6f6;
    }
  }
}
