.create {
  &-banner {
    text-align: center;
    padding-top: 350px;
    background-color: $primary-color;
    height: 519px;
    position: relative;
    z-index: 2;

    img {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 75%;
      height: 519px;
      width: 100%;
      object-fit: cover;
    }

    &-title {
      position: absolute;
      z-index: 3;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 31pt;
      color: #FFFFFF;
      max-width: 426px;
      text-align: start;
      top: 68px;
      left: 56px;
      line-height: 55px;

      @media (max-width: 360px) {
        top: 20px;
        left: 40px;
        font-size: 28pt;
      }

      @media (max-width: 420px) {
        font-size: 24pt;
      }
    }

    &-text {
      position: absolute;
      max-width: 473px;
      text-align: start;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 15pt;
      line-height: 27px;
      color: #F8F8F8;
      left: 56px;
      top: 255px;

      @media (max-width: 360px) {
        top: 246px;
        left: 40px;
        font-size: 16pt;
      }

      @media (max-width: 420px) {
        font-size: 12pt;
      }
    }

    &-register {
      z-index: 3;
      position: absolute;
      width: 168px;
      background: #BE00FF;
      border-radius: 8px;
      height: 40px;
      left: 56px;
      margin-top: 53px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF !important;
      cursor: pointer;

      &-login {
        background: transparent;
        border: 1px solid #FFFFFF;
        z-index: 3;
        margin-top: 53px;
        position: absolute;
        width: 168px;
        border-radius: 8px;
        height: 40px;
        left: 255px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF !important;
        cursor: pointer;

        @media (max-width: 500px) {
          margin-top: 0px;
          top: 400px;
        }

        @media (max-width: 420px) {
          left: 33vw;
          top: 455px;
        }

      }

      @media (max-width: 500px) {
        margin-top: 0px;
        top: 400px;
      }

      @media (max-width: 420px) {
        left: 33vw;
      }
    }

    @media (max-width: 500px) {
      padding-top: 500px;
      height: auto;
      width: 100%;
      display: block;
    }

    @media (max-width: 325px) {
      padding-top: 500px;
      height: auto;
      width: 100%;
      display: block;
    }

    &__title {
      font-size: 1.2rem;
      margin-bottom: 20px;
      font-weight: bold;
      color: $primary-color-darker;
    }

    &__description {
      margin-bottom: 40px;
    }
  }
}