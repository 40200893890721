.button {
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    cursor: pointer;

    &__secondary {
        background: transparent;
        color: $text-gray;

        &:hover {
            color: $text-dark-gray;
        }
    }

    &__outline {
        background: transparent;
        color: #313234;
        border: 1px solid $primary;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
    }

    &__color {
        &__primary {
            color: $text-gray;

            &:hover {
                color: $text-dark-gray;
            }
        }

        &__secondary {
            color: $primary;

            &:hover {
                color: $primary-hover;
            }
        }

        &__outline {
            color: #313234;

            &:hover {
                color: $primary;
            }
        }
    }

    &__primary {
        background: $primary;
        padding: 8px 22px;
        border-radius: $radius;
        color: $white3;
        text-transform: uppercase;

        &:hover {
            background: $primary-hover;
            color: $white3;
        }
    }
}