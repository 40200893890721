.deposit-ticket {
  border: 1px solid #EBECED;
  box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
  border-radius: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &__value {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: $primary-color;
  }

  &__data {
    color: $text-gray;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__information {
    &__title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #313234;
      text-transform: uppercase;
    }

    &__list {
      &__item {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #66696F;
        margin-top: 8px;
      }
    }
  }

  hr {
    border-style: dashed;
    width: calc(100% + 64px);
    margin-left: -32px;
  }

  &__back-button {
    display: flex;
    width: 100%;
    justify-content: center;
    text-decoration: none !important;

    button {
      box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
      border-radius: 32px;
      background: $primary-color;
      border: none;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $white;
      text-transform: uppercase;
      padding: 8px;

      width: 100%;
      max-width: 300px;
      cursor: pointer;
    }
  }

  &__change-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #BE00FF;
    background: none;
    border: none;
    display: flex;
    cursor: pointer;

    &:hover {
      color: $primary-color-dark;
    }
  }

  &__continue-button {
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
    border-radius: 32px;
    background: $primary-color;
    border: none;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    text-transform: uppercase;
    padding: 8px;

    width: 100%;
    max-width: 300px;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  @media (max-width:600px) {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;

    &__footer {
      flex-direction: column;

      button {
        &:last-child {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }

}

.btn {
  &__back {
    padding-left: 10px;
    color: #4197e3;
    background: none;
    border: none;
    cursor: pointer;
    color: $secondary-color;

    &:hover {
      color: $secondary-color-dark;
    }
  }
}

.content-opt {
  margin-bottom: 20px;
}

.content-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.boleto {
  &__description {
    &__text {
      margin-top: 10px;
      color: red;
      justify-content: center;
      display: flex;
      text-align: center;
    }
  }

  &__content {
    margin: 30px 50px 0px;
    padding: 0 30px;

    @media (max-width: 600px) {
      margin: 0;
      padding: 15px;
    }

    &__ob-text {
      margin-bottom: 14px;
      display: block;
      color: $primary-color-darker;
      width: 100%;
      border: none;
      outline: none;
      transition: none;
      max-width: 100%;
    }

    &__title {
      font-size: 0.7em;
      font-weight: bold;
      display: block;
      color: $secondary-color;
      letter-spacing: 3.3px;
      text-transform: uppercase;
    }

    &__text {
      margin-bottom: 14px;
      display: block;
      color: $primary-color-darker;
      width: 100%;
      border: none;
      padding: 7px 0 14px;
      outline: none;
      transition: none;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &__list {
      list-style: none;
      padding-left: 0;
    }

    &__list li {
      padding-top: 0.7rem;
      font-size: 0.9rem;
    }

    &__list li::before {
      content: attr(dataicon);
    }
  }

  &__mobile {
    &__back {
      background: $secondary-color;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      color: white;
      font-weight: bold;
      height: 7vh;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 0.9rem;
    }
  }

  &__input {
    &__money {
      margin: 20px 0 0;
      text-align: center;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    margin: 20px;

    @media (max-width: 600px) {
      zoom: 0.8;
      margin: 20px 0;
    }

    &__add {
      font-size: 10pt;
      cursor: pointer;
      color: $secondary-color;
      border: solid 1px $secondary-color;
      background: #fff;
      border-radius: 22px;
      padding: 7px;
      margin: 0 5px;

      &:hover {
        color: #fff;
        background: $secondary-color;
        transition: all 0.2s ease-in;
      }

      span {
        padding: 5px;
      }
    }

    &__submit {
      cursor: pointer;
      color: #fff;
      border: none;
      background: $line-gray;
      border-radius: 22px;
      padding: 10px 20px;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media (max-width: 600px) {
        zoom: 1.4 !important;
        margin: -10px 0 !important;
      }

      &--enable {
        background: $secondary-color;
        transition: all 0.2s ease-in;
      }
    }
  }

  &__outter {
    margin: 20px 0;
    width: 100%;

    @media (max-width: 600px) {
      margin: 0;
    }
  }

  &__wrapper {
    padding: 10px 15px;

    &--step-two {
      padding: 10px 30px 25px;
    }

    &--step-two-header {
      padding: 25px 30px 10px;

      @media (max-width: 600px) {
        padding: 25px 15px 10px;
      }
    }
  }

  &__title {
    color: $primary-color-darker;
    font-size: 14pt;

    @media (max-width: 600px) {
      margin-top: 10px;
    }
  }

  &__balance {
    color: $primary-color-darker;
    margin: 4px 0 20px;

    &__value {
      color: $secondary-color;
    }
  }

  &__step-two {
    &__title {
      color: $primary-color-darker;
      font-size: 14pt;
      font-weight: bold;
      margin-top: 7px;
      margin-bottom: 7px;
    }

    &__text {
      margin: 13px;
      font-size: 10pt;
      color: $primary-color-darker;
    }

    &__value {
      color: $secondary-color;
      font-size: 20pt;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__user {
      background: #f5f5f5;
      border-top: 2px dashed #c5c5c5;
      border-bottom: 2px dashed #c5c5c5;
      padding: 20px 50px 5px;

      @media (max-width: 600px) {
        padding: 20px 20px 0;
      }
    }

    &__disclaimer {
      background: #f5f5f5;
      border-top: none;
      border-bottom: 2px dashed #c5c5c5;
      padding: 20px 50px 5px;

      @media (max-width: 600px) {
        padding: 20px 20px 0;
      }
    }
  }

  &__step-three {
    &__text {
      margin: 13px;
      font-size: 14pt;
      color: $primary-color-darker;
    }

    &__barcode-title {
      text-align: center;

      @media (max-width: 600px) {
        padding: 10px;
      }
    }

    &__barcode {
      text-align: center;
      border-bottom: 2px dashed #dedede;

      @media (max-width: 600px) {
        padding: 10px;
      }
    }

    &__barcode svg {
      cursor: default !important;
    }

    &__header {
      padding: 20px;

      @media (max-width: 600px) {
        padding: 10px;
      }

      &__title {
        margin: 10px 0 0;
        font-size: 14pt;
        color: $primary-color-darker;
        font-weight: bold;
      }

      &__value {
        color: $green;
        font-size: 20pt;
        font-weight: bold;
      }

    }

    &__code {
      text-align: center;
      padding: 10px 70px;
      border-top: 2px dashed #dedede;
      border-bottom: 2px dashed #dedede;

      @media (max-width: 600px) {
        padding: 10px;
      }

      &__number {
        color: $secondary-color;
      }
    }

    &__options {
      padding: 15px;
      color: $primary-color-darker;
      border-bottom: 2px dashed #dedede;

      svg {
        cursor: pointer;
      }

      &__title {
        margin: 7px 7px 15px;
        color: $primary-color-darker;
      }

      &__payment {
        color: $secondary-color;
        cursor: pointer;
        margin: 7px 0;

        &:hover {
          transition: all ease-in 0.2s;
          color: $secondary-color-dark;
        }
      }
    }

    &__disclaimer {
      background: #f5f5f5;
      padding-bottom: 0;
      border-bottom: none;
      border-top: 2px dashed #c5c5c5;
      padding: 20px 50px 5px;

      @media (max-width: 600px) {
        padding: 20px 20px 0;
      }
    }

    &__footer {
      padding: 15px;

      @media (max-width: 600px) {
        padding: 10px;
      }
    }
  }
}

.icon {
  &__back {
    cursor: pointer;
  }
}

.btn {
  &__text {
    border: none;
    background: none;
    padding: 0;
    color: $secondary-color;
    cursor: pointer;
    margin: 7px 0;

    &:hover {
      transition: all ease-in 0.2s;
      color: $secondary-color-dark;
    }
  }
}