.card {

  &--white {
    background-color: #fff;
    border: none;
    box-shadow: 0px 10px 32px #00000024;
  }

  &--green {
    background-color: $water-blue;
    border: 1px solid $blue-border;
    padding: 0;
  }
}