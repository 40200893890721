.card {
  &__company {
    height: 25px;
    width: 40px;

    &-black {
      fill: #000;
    }

    &-hidden {
      visibility: hidden;
      height: 50px;
    }
  }
}
