.update-data-form {
    &__grid1 {
        display: grid;
        grid-template-columns: 1fr 1fr 0.5fr;
        gap: 24px;
    }

    &__grid2 {
        display: grid;
        grid-template-columns: 0.3fr 1fr 0.3fr 0.5fr;
        gap: 24px;
        margin-top: 16px;
    }

    &__grid3 {
        display: grid;
        grid-template-columns: 0.5fr 1fr 0.5fr;
        gap: 24px;
        margin-top: 16px;
    }

    &__submit-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        gap: 16px;
    }
}