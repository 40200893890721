.panel_start {
  padding: 40px;
  display: flex;
  width: 100%;

  &__links {
    display: flex;
    margin-left: 24px;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
  }

  &__loan {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
    height: auto !important;
    padding: 32px;

    &__header {
      flex: 1;

      h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #313234;
        margin: 0 !important;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #73757d;
        margin: 0 !important;
      }
    }

    &__button {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #666666;
      background: transparent;
      border: none;
      outline: none;
      margin-top: 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__section_button {
    padding: 32px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);

    button {
      margin-top: 0;
    }

    margin-top: 24px;
  }

  @media screen and (max-width: 600px) {
    border: none;
    width: 100%;
    padding: 0 16px;

    flex-direction: column;

    &__section_button {
      display: none;
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }
}