// profile-photo

.profile-photo {
  &__container {
    background-size: cover;
    background-position: top center;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__configuration {
    background-size: cover;
    background-position: center;
    width: 96px;
    height: 96px;
    overflow: hidden;
    border-radius: 50%;
  }
}