.two-labels-input {
    display: flex;
    align-items: center;
    height: 42px;
    width: 100%;

    border-radius: 20px;
    border: 1px solid $gray-border;

    &__component {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;

        input {
            border: none;
            background: transparent;
            padding: 0 16px;
            width: 100%;
            height: 100%;
        }

        &:first-of-type {
            width: 65%;

            input {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }

        }

        &:last-of-type {
            border-left: 1px solid $gray-border;
            width: 35%;

            input {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }

        span {
            color: #BEC5CB;
            position: absolute;
            top: 10px;
            left: 16px;
            font-size: 14px;
            letter-spacing: 0.05em;
            pointer-events: none;
            transition: .2s;
        }

        input:focus+span,
        input:valid+span,
        input:read-only+span {
            top: -8px;
            left: 12px;
            font-size: 10px;
            padding: 0 8px;
            background: $background;
            color: $text-dark-gray;
        }
    }

    &__error {
        border-color: $error;

        div {
            &:last-of-type {
                border-left-color: $error;
            }
        }
    }

    &__active {
        border-color: $text-dark-gray;
    }

    &__active {
        div {
            &:last-of-type {
                border-left-color: $text-dark-gray;
            }
        }
    }
}