.select-account-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    align-items: flex-start;
    border: 1px solid $border;
    border-radius: 8px;

    cursor: pointer;

    &__check {
        display: flex;
        align-items: center;

        &__circle {
            background: $background;
            border: 1px solid $border;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transition: ease-in-out 200ms;
            display: flex;
            align-items: center;
            justify-content: center;

            &__dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $background;
            }

            &.checked {
                background: $primary;
            }
        }

        &__label {
            margin: 0;
            margin-left: 8px;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: $text-gray;

            &.checked {
                color: $primary;
            }
        }
    }

    &__divider {
        border: 1px dashed $border;
        width: 100%;
    }

    &__content {
        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $text-dark-gray;
        }

        p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: $text-gray;
        }
    }

    &.checked {
        border-color: $primary;
    }
}