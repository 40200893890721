.card {
  &__title {
    color: $primary-color-darker;
    font-weight: bold;
    font-size: 14pt;
    margin: 7px 0 25px;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 50px 0px;

    @media (max-width: 600px) {
      padding: 5px 20px;
    }

    &__inner {
      border: none;
      border-radius: 22px;
      box-shadow: 0px 10px 18px #0000001a;
      height: 40px;
      margin: 5px 0;
      width: 400px;
      padding: 5px 20px;

      &--smaller {
        width: 150px;
      }
    }
  }

  &__container {
    margin: 5px 0 10px;

    @media (max-width: 600px) {
      zoom: 0.8 !important;
    }
  }

  &__form {
    &__action {
      margin: 15px 0 10px;
    }
  }
}

.invalid {
  border-radius: 22px;
  border: 1px solid $red;
  box-shadow: 0 0 3px $red;
  transition: all 0.2s ease-in;
}
