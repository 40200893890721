// footer

.footer {
  background-color: $primary-color;
  padding: 15px;
  bottom: 0px;
  position: relative;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 40px;
    padding: 5px;
  }

  &__center {
    padding-top: 3px;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-links {
      margin: auto;
      display: flex;

      &__single {
        padding: 0 70px;
        font-size: 0.8rem;
        color: $white;
        text-transform: uppercase;

        @media (max-width: 1024px) {
          margin-bottom: 20px;
          padding: 0 15px;
        }

        &:hover {
          color: $white;
          text-decoration: none;
          transition: ease-in 0.2s;
        }
      }
    }
  }
}
