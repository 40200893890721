// Spacing
@for $i from 1 through 10 {
  .mt-#{$i} {
    margin-top: #{$i}rem !important;
  }
}

@for $i from 1 through 10 {
  .mb-#{$i} {
    margin-bottom: #{$i}rem !important;
  }
}

@include mq('md') {
  @for $i from 1 through 10 {
    .mt-md-#{$i} {
      margin-top: #{$i}rem !important;
    }
  }

  @for $i from 1 through 10 {
    .mb-md-#{$i} {
      margin-bottom: #{$i}rem !important;
    }
  }
}

@include mq('lg') {
  @for $i from 1 through 10 {
    .mt-lg-#{$i} {
      margin-top: #{$i}rem !important;
    }
  }

  @for $i from 1 through 10 {
    .mb-lg-#{$i} {
      margin-bottom: #{$i}rem !important;
    }
  }
}

@include mq('xl') {
  @for $i from 1 through 10 {
    .mt-xl-#{$i} {
      margin-top: #{$i}rem !important;
    }
  }

  @for $i from 1 through 10 {
    .mb-xl-#{$i} {
      margin-bottom: #{$i}rem !important;
    }
  }
}

@include mq('xxl') {
  @for $i from 1 through 10 {
    .mt-xxl-#{$i} {
      margin-top: #{$i}rem !important;
    }
  }

  @for $i from 1 through 10 {
    .mb-xxl-#{$i} {
      margin-bottom: #{$i}rem !important;
    }
  }
}
