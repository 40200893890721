.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
}

.input {
    position: relative;
    margin-top: 16px;

    input {
        width: 100%;
        height: 42px;

        padding: 0 16px;

        background: transparent;

        border: 1px solid $gray-border;
        border-radius: 20px;

        font-weight: 400;
        font-size: 14px;
    }

    span {
        color: $gray-border;
        position: absolute;
        top: 10px;
        left: 16px;
        font-size: 14px;
        letter-spacing: 0.05em;
        pointer-events: none;
        transition: .2s;
    }

    input:focus,
    input:valid,
    input:read-only {
        border-color: $text-dark-gray;
    }

    input:focus+span,
    input:valid+span,
    input:read-only+span {
        top: -8px;
        left: 12px;
        font-size: 10px;
        padding: 0 8px;
        background: $background;
        color: $text-dark-gray;

        &.input__secondary-background {
            background: $background-secondary;
        }
    }

    &__error {
        color: $error;
        font-size: 12px;
        line-height: 12px;
        text-align: left;
        margin-top: 4px;
    }

    &__border-error {
        input {
            border-color: $error;
        }
    }
}