.add-credit-card-modal {
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: $background;
    border-radius: 16px;

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;

        div {
            flex: 1;

            h4 {
                font-weight: 800;
                font-size: 24px;
                line-height: 30px;
                margin: 0;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin: 0;
            }

            hr {
                height: 2px;
                width: 50px;
                background: linear-gradient(90.01deg, #FD6404 1.99%, #C029F4 99.98%);
                border-radius: 3px;
                margin: 0;
                margin-top: 8px;
            }
        }

        svg {
            width: 14px;
            cursor: pointer;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__form-wrapper {
        display: flex;
        align-items: center;
        gap: 32px;
    }

    &__flags {
        margin-top: 16px;
        align-self: flex-start;

        img {
            width: 42px;

            &:last-of-type {
                margin-left: 8px;
            }
        }
    }

    &__row {
        display: flex;
        width: 100%;
        gap: 16px;
    }


    &__submit-button {
        margin-top: 32px;
        background: $primary;
        border-radius: 8px;
        border: none;
        padding: 16px;

        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $white;

        max-width: 300px;
        align-self: center;
        cursor: pointer;

        &:disabled {
            background: red;
        }
    }

    @media (max-width:600px) {
        &__row {
            flex-direction: column;
            gap: 0;
        }
    }
}