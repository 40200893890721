.mobile-filters {
  &__wrapper {
    height: 100vh;
    position: absolute;
    top: 0;
    background: $white;
    width: 100vw;
    z-index: 9999;
    padding: 15px;

    animation-name: filters;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 7px;
    color: $primary-color;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 10pt;
    margin-bottom: 7px;
    margin-top: 15px;
    color: $primary-color;
    text-transform: uppercase;
  }

  &__option {
    border: none;
    height: 45px;
    width: 95%;
    background: #f2f2f2;
    padding: 7px 20px;
    margin: 2px 0;
    font-size: 0.9rem;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: start;

    &--selected {
      color: $secondary-color;
      background: rgba(65, 151, 227, 0.25);
      transition: all ease-in 0.2s;
    }

    &__icon {
      right: 50px;
      position: absolute;

      animation-name: done;
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
    }
  }

  &__close {
    svg {
      fill: $primary-color;
    }
  }

  &__action {
    margin-right: 15px;
    background: $secondary-color !important;
  }
}

@keyframes done {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes filters {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
