// emtpy state
.empty-state {
  &__icon {
    padding: 60px 0 30px;
  }

  &__title {
    font-size: 20pt;
    font-weight: bold;
    margin-top: 5px;
  }

  &__description {
    font-size: 12pt;
  }
}
