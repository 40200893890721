.select-input {
    position: relative;
    height: 42px;
    width: 100%;

    span {
        color: #BEC5CB;
        position: absolute;
        top: 10px;
        left: 16px;
        font-size: 14px;
        letter-spacing: 0.05em;
        pointer-events: none;
        transition: .2s;
    }

    &__active {
        border-color: $text-dark-gray;
    }

    &__active {
        span {
            top: -8px;
            left: 12px;
            font-size: 10px;
            padding: 0 8px;
            background: $background;
            color: $text-dark-gray;
        }
    }

    &__error {
        color: $error;
        font-size: 12px;
        text-align: left;
        margin-top: 4px;
    }

    &__border-error {
        input {
            border-color: $error;
        }
    }
}