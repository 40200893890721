.icon {
  svg {
    fill: $secondary;
  }

  &__logo {
    fill: #fff;
    width: 140px;
    height: 40px;
  }

  &__wrapper {
    border: 1px solid #e4e4e4;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    @media (max-width: 600px) {
      width: 30px;
      height: 30px;
    }
  }
}

.item {
  &__wrapper {
    padding: 8px 4px;
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    background: none;
    display: flex;
    align-items: center;
    margin: 0;

    @media (max-width: 600px) {
      align-items: flex-start;
    }

    &:hover {
      border-radius: 3px;
      box-shadow: 0 0 5px #a7cae8;
      border: 1px solid #a7cae8;
      transition: all 0.2s ease-in;
    }
  }
}

.text {
  font-size: 16px;
  color: $primary-color-darker;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }

  &__operation {
    font-size: 12px;
    font-weight: regular;
  }

  &-yellow {
    color: $yellow;
    font-weight: 600;
    text-align: center;

    @media (max-width: 600px) {
      font-weight: normal;
    }
  }

  &-light-blue {
    color: $light-blue;
    font-weight: 600;

    @media (max-width: 600px) {
      font-weight: normal;
    }
  }

  &-red {
    color: $red;
    font-weight: 600;

    @media (max-width: 600px) {
      font-weight: normal;
    }
  }

  &-green {
    color: $green;
    font-weight: 600;

    @media (max-width: 600px) {
      font-weight: normal;
    }
  }

  &-blue {
    color: $secondary-color-dark;
    font-weight: 600;

    @media (max-width: 600px) {
      font-weight: normal;
    }
  }
}