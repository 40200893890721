// header

.header {
  padding: 0 32px 6px;
  background-color: $white;
  height: 75px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);

  @media (max-width: 600px) {
    height: 100px;
  }

  // img {
  //   width: 128px;
  //   height: 40px;
  // }

  // &::after {
  //   content: '';
  //   width: 100%;
  //   height: 6px;
  //   background: $gray;

  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  // }

  &__left {
    &__profile-name {
      display: flex;
      align-items: center;
      margin-left: 8px;
      color: $primary;
    }
  }

  &__margin {
    margin-top: 55px;
  }
}

.icon {
  width: 32px;
  height: 32px;
  color: $primary-color;
  border-radius: 50%;
  background: $dark-white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  align-self: center;
  color: $primary-color;
  cursor: pointer;
}


.disclaimer {
  height: 55px;
  z-index: 1000;
  position: fixed;
  width: 100vw;
  top: 0px;
  background-color: #FFEFD6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #66696F;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 700;
  svg {
    overflow: visible;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    text-align: center;
    height: auto;
    max-height: 100px;
    overflow: scroll;
  }
}

.actions {
  display: flex;
}

.balance_container {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    width: 1px;
    height: 38px;
    background: $gray;
  }
}

.balance_box {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  flex: 1;
}

.balance_title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $dark-gray;
}

.balance_value {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: $primary-color;
  display: flex;
  align-items: center;
}

.balance_icon {
  margin-right: 16px;
  color: $primary-color;
  cursor: pointer;
}

.balance_hidden {
  width: 105px;
  height: 4px;
  background: $white;
  margin: 0 8px;
}

.profile {
  position: relative;
}

.profile_avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.menu {
  position: absolute;
  background-color: $white;
  top: calc(100% + 16px);
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;

  z-index: 999;
  border-radius: 8px;
  box-shadow: 4px 4px 80px rgba(11, 47, 99, 0.24);
}

.menu-button {
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  width: 100%;

  padding: 8px 16px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: $dark-gray;
  cursor: pointer;

  svg {
    margin-right: 6px;
    fill: $dark-gray;
  }

  &:hover {
    text-decoration: none;
    color: $primary-hover;

    svg {
      fill: $primary-hover;
    }
  }
}