.extract {
  &__card {
    &__content {
      padding: 15px 0;
      border-bottom: 2px dashed $line-gray;
      margin-bottom: 15px;

      &__user {
        margin-bottom: 10px;
      }

      &__account {
        display: flex;
        flex-direction: column;

        &__info {
          display: flex;
          align-items: baseline;
          margin: 5px 0;

          &__title {
            font-size: 0.75rem;
            color: $primary-color;
            letter-spacing: 1.3px;
            text-transform: uppercase;
            margin-right: 5px;
          }

          &__description {
            font-size: 0.75rem;
            color: $primary-color;
            letter-spacing: 1.3px;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }

      &__value {
        padding: 2px 0;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        &__title {
          font-size: 0.8rem;
          color: $primary-color;
          letter-spacing: 1.3px;
          text-transform: uppercase;
        }

        &__description {
          font-size: 1rem;
          font-weight: bold;
          color: $primary-color;
          letter-spacing: 1.3px;
          text-transform: uppercase;
        }
      }
    }
  }
}
