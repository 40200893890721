.panel__support {
  max-width: 40vw;
  padding-left: 90px;

  @media (max-width: 600px) {
    padding: 0;
    max-width: 100vw;
  }
  &__text {
    margin: 2px;
    color: $primary-color;
  }
}

.support-phone {
  &__card {
    padding: 15px;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 7px;

    &--with-shadow {
      border: none;
      box-shadow: 0px 10px 18px #00000029;
    }

    header {
      text-align: center;
    }

    main {
      padding: 15px 10px 10px;
      color: #fff;
      background: $secondary-color;
      text-align: center;
      border-radius: 7px;
    }

    @media (max-width: 600px) {
      border-radius: 0px;
    }
  }

  &__title {
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: $secondary-color;
  }

  &__phone {
    font-size: 1.2rem;
    color: $primary-color;
  }

  &__description {
    // color: red;
  }

  &__protocol-number {
    font-size: 1.7rem;
    margin-top: 7px;
    letter-spacing: 8px;
  }
}

.support-email {
  &__character-counter {
    font-size: 0.9rem;
    color: $gray-text-color;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 600px) {
      margin: 0 15px;
    }
  }

  &__text {
    padding: 10px;
    border-radius: 5px;
    color: $primary-color;
    margin-bottom: 10px;
    width: 98%;
    margin: 2px;
    border: 1px solid #c9c9c9;
  }

  &__button {
    margin-top: 15px;
    cursor: pointer;
    background: $secondary-color;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    color: #fff;

    &:hover {
      background: $secondary-color-dark;
      transition: ease-in 0.2s;
    }

    &__wrapper {
      @media (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.support-header {
  margin-bottom: 30px;

  @media (max-width: 600px) {
    background: $secondary-color;
    padding: 0 15px;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    color: $primary-color;

    @media (max-width: 1400px) {
      font-weight: bold;
      font-size: 22px;
      text-align: center;
    }
  }

  &__link {
    font-size: 0.8rem;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    margin-top: 20px;
    color: $secondary-color;

    &--no-underline {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: $secondary-color;

      &:hover {
        color: $primary-color;
        text-decoration: none;
        transition: ease-in 0.2s;

        svg {
          fill: $primary-color;
          transition: ease-in 0.2s;
        }
      }
    }

    svg {
      margin-right: 10px;
    }

    @media (max-width: 600px) {
      margin-top: 5px;
      a {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
  }
}

.support-message {
  &__wrapper {
    border: 1px solid #fff;
    border-radius: 28px;
    padding: 20px;
  }

  &__sent {
    text-align: center;

    @media (max-width: 600px) {
      padding: 15px 0px 1px;
      background: #f6f6f6;
    }

    &__description {
      margin-bottom: 20px;
      color: $primary-color;
    }

    &__icon {
      fill: $secondary-color !important;
    }

    &__text {
      color: $primary-color;
      font-size: 1.1rem;
      margin: 15px 0 30px;

      @media (max-width: 600px) {
        magin: 0;
      }
    }
  }
}

.textarea {
  &__label {
    color: $primary-color;
  }
}

textarea::-webkit-input-placeholder {
  color: #c9c9c9;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c9c9c9;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c9c9c9;
}

textarea:-ms-input-placeholder {
  color: #c9c9c9;
}

textarea::placeholder {
  color: #c9c9c9;
}
