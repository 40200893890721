.modal {
  &__warning {
    &__close {
      @media (max-width: 430px) {

        margin-top: 40px;
      }

      @media (max-width: 320px) {
        margin-top: 0px;
      }

      &__button {
        border: none;
        color: $primary;
        background: #fff;
        font-size: 2em;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        cursor: pointer;

        @media (max-width: 370px) {
          font-size: 1.4rem;
        }
      }
    }

    &__title {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      padding: 20px 80px 0px 80px;
      margin-bottom: 10px;

      @media (max-width: 370px) {
        font-size: 1.6rem;
      }
    }

    &__text {
      font-size: 1.8rem;
      padding: 0px 75px 0px 75px;
      margin-bottom: 20px;

      @media (max-width: 370px) {
        padding: 0px 60px 0px 60px;
        font-size: 1.4rem;
      }
    }
  }

  &__deposit {

    &__close {
      @media (max-width: 430px) {
        margin-top: 40px;
      }

      @media (max-width: 320px) {
        margin-top: 0px;
      }

      &__button {
        border: none;
        margin: 30px 35px 0px 0px;
        background: #fff;
        font-weight: bold;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
      }
    }

    &__image {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      padding: 0px 80px 0px 80px;
      margin-bottom: 10px;

      @media (max-width: 320px) {
        font-size: 15px;
      }
    }

    &__textOne {
      font-size: 14px;
      padding: 0px 75px 0px 75px;
      margin-bottom: 20px;

      @media (max-width: 320px) {
        padding: 0px 60px 0px 60px;
        font-size: 13px;
      }
    }

    &__textTwo {
      font-size: 14px;
      padding: 0px 75px 0px 75px;
      margin-bottom: 10px;

      @media (max-width: 320px) {
        padding: 0px 60px 0px 60px;
        font-size: 13px;
      }
    }

    &__continue {
      &__button {
        margin-bottom: 10px;
        border-radius: 5px;
        width: 125px;
        height: 50px;
        background: #4197e3;
        color: white;
        border: none;
        font-weight: 500;
      }
    }
  }
}