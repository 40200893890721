.login {
  height: 100%;
  background-color: $secondary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;

  &__logo {
    margin-top: 40px;

    img {
      width: 270px;
      height: 84px;
    }

    @media (max-width: 600px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .container {
    margin-top: 30px;
  }

  &__container {
    &__form {
      &__small {
        font-style: italic;
      }
    }
  }

  .container-code {
    margin-top: 60px;

    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

  &__bottom__links {
    text-align: center;
    width: 60%;
    font-size: 9pt;
    margin-top: 30px;

    a {
      color: $primary-color-dark;
    }

    @media (max-width: 600px) {
      width: 85%;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

.media-no-padding {
  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.media-no-radius {
  @media (max-width: 600px) {
    border-radius: 0 !important;
  }
}