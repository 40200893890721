// loading
$spinner-size: 80px;

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.90);
  z-index: 10000;

  &__inner {
    position: absolute;
    top: calc(50% -  40px);
    right: 0;
    left: 0;
    width: $spinner-size;
    height: $spinner-size;
    display: block;
    margin: auto;

    &__spinner {
      display: block;
      width: $spinner-size;
      height: $spinner-size;
    }
  }
}
