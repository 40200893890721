.institutional {
  &__terms {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    @media (max-width: 1024px) {
      padding: 20px 0 10px;
    }

    &__description {
      width: 70%;

      @media (max-width: 1024px) {
        width: 100%;
        padding: 0 20px;
      }
    }

    &__toggler {
      width: 70%;
      padding-right: 20%;

      @media (max-width: 1024px) {
        width: 100%;
        padding: 0 30px;
        margin-bottom: 50px;
      }

      &__title {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 15px;
        color: $primary-color;
      }

      &__action {
        &__single {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-color;
          width: 200px;
          padding: 5px 10px;
          border: 1px solid #0095c8;
          border-radius: 5px;
          margin: 20px 0;
          font-weight: bold;

          &:hover {
            color: #f9f9f9;
            background: #0095c8;
            transition: ease-in 0.2s;
          }
        }
      }
    }
  }
}
