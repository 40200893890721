.card {
  border-radius: 19px;
  padding: 15px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
}


.no-radius {
  border-radius: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.no-shadow {
  box-shadow: none !important;
  border: none !important;
}

.d-block {
  width: 80% !important;
  display: block !important;

  @media all and (max-width: 1440px) {
    width: 100% !important;
  }
}
