.institutional {
  &__section {
    &__header {
      padding: 25px 0;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
      color: $primary-color;
    }
  }
}

.pillar {
  &__banner {
    text-align: center;
    padding-top: 300px;
    background-image: url('/assets/images/banner_about.png');
    border-top: 5px solid $secondary-color;
    background-repeat: no-repeat;

    @media (max-width: 500px) {
      padding-top: 500px;
      background-position: center center;
      background-image: url('/assets/images/banner_about_mobile_middle.png');
    }

    @media (max-width: 325px) {
      background-image: url('/assets/images/banner_about_mobile.png');
      height: auto;
      width: 100%;
      display: block;
    }
  }

  &__steps {
    padding: 40px 0;
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 0;
    }

    &__single {
      max-width: 25vw;

      @media (max-width: 1024px) {
        max-width: 100vw;
      }

      &__number {
        color: #fff;
        width: 70px;
        font-weight: bold;
        height: 70px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 2rem;
        border-radius: 50%;
        border: 1px solid $secondary-color;
      }

      &__image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: #eeeef0;
      }

      &__title {
        font-size: 1.2rem;
        margin-top: 20px;
        letter-spacing: 3.3px;
        color: $secondary-color;
        text-transform: uppercase;
      }

      &__description {
        margin: 30px 0 0;
        padding-top: 20px;
        text-align: center;

        @media (max-width: 1024px) {
          margin: 0;
          padding: 20px;
        }
      }
    }
  }
}

.about {
  &__section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &__container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &__image {
        width: 484px;
      }

      &--placeholder {
        width: 300px;
        height: 300px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;

      &__single {
        display: flex;
        align-items: center;
        margin: 20px;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #0098e6;

          svg {
            fill: #d9eafa;
          }
        }

        &__divider {
          width: 70%;
          height: 1px;
          background: #acacac;
          opacity: 0.2;

          @media (max-width: 1024px) {
            width: 100%;
          }
        }

        &__content {
          text-align: left;
          margin-left: 30px;

          &__description {
            width: 70%;
            margin-bottom: 10px;

            @media (max-width: 1024px) {
              width: 100%;
            }
          }

          &__link {
            padding-top: 4px;
            color: #0095c8;
            border-top: 2px solid #d9eafa;

            &:hover {
              color: $primary-color;
              transition: ease-in 0.2s;
            }
          }
        }
      }
    }
  }
}