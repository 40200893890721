.institutional {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #BE00FF;
    padding: 1rem 2rem;
    background: #FFFFFF;

    @media (max-width: 1024px) {
      padding: 1rem 1rem;
    }
  }

  &-footer {
    &__navigation {
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
    }

    &__copyright {
      margin-top: 60px;
      color: #7986a1;
      text-align: center;
      font-size: 0.75rem;
    }

    &-links {
      margin: auto;
      display: flex;

      @media (max-width: 1024px) {
        margin: 0;
        flex-direction: column;
      }

      &__single {
        padding: 0 30px;
        font-size: 0.75rem;
        color: #BE00FF;
        text-transform: uppercase;

        @media (max-width: 1024px) {
          margin-bottom: 20px;
          padding: 0 15px;
        }

        &:hover {
          color: #fff;
          text-decoration: none;
          transition: ease-in 0.2s;
        }
      }
    }

    &__create {
      padding: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      color: #fff;
      background:  #BE00FF;
      @media (max-width: 1024px) {
        flex-direction: column;
      }

      &__text {
        font-size: 18pt;
        margin: auto;
        font-weight: bold;
        text-align: center;

        @media (max-width: 1024px) {
          margin-bottom: 15px;
        }
      }

      &__button {
        margin: auto;
        color: #BE00FF;
        background: #e2f8ff;
        border-radius: 3px;
        padding: 5px 15px;
        font-size: 0.8rem;
        border: none;

        &:hover {
          text-decoration: none;
          color: #e2f8ff;
          background: $primary-color-darker;
          transition: ease-in 0.2s;
          border: 1px solid $primary-color-darker;
        }
      }
    }
    &__ombudsman {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 32px;

      &__title {
        font-size: 15px;
        color: #313234;
        font-family: 'Inter';
        font-weight: 600;
      }
      &__subtitle {
        font-size: 12px;
        color: #666666;
        font-family: 'Inter';
        font-weight: 400;
        line-height: 24px;
        max-width: 560px;
      }
    }
  }
}

.login {
  &-actions {
    &__link {
      color: #BE00FF;

      &:hover {
        text-decoration: none;
        color: #BE00FF;
        transition: ease-in 0.2s;
      }
    }

    &__divider {
      display: inline;
      margin: 0rem 1rem;
      border: 1px solid #EBECED;
    }
  }
}

.institutional-route {
  &__content {
    min-height: 100vh;
    position: relative;
    display: block;
  }

  &__footer {
    bottom: 0;
    width: 100%;
  }
}
