.input {
  &__money {
    &__wrapper {
      padding: 15px;
      box-shadow: 0px 6px 18px #00000029;
      border-radius: 80px;
      height: 61px;
      width: 277px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      margin: auto;
    }
    &__currency {
      display: inline;
      font-weight: bold;
      font-size: 16pt;
      color: $secondary-color;
      padding-right: 5px;
    }
    &__field {
      text-align: center;
      font-size: 20pt;
      border: none;
      outline: none;
      width: 100%;
      line-height: 32px;
      align-items: center;
      color: $secondary-color;
      font-weight: bold;
      caret-color: $secondary-color;

      &__placeholder {
        position: absolute;
        left: 40%;
        font-size: 20pt;
        font-weight: bold;
        color: $secondary-color;

        @media (max-width: 600px) {
          left: 36%;
        }
      }
    }
  }
}
