.list {
  padding: 15px;
  background: #fff;
  border-top: 2px #c5c5c5 dashed;
  border-bottom: 2px #c5c5c5 dashed;
  display: flex;
  justify-content: center;
  margin-bottom: 90px;

  &__container {
    width: 65vw;
    justify-content: center;

    @media (max-width: 600px) {
      width: 85vw;
    }
  }
}
