.card {
  &__item {
    &__wrapper {
      display: flex;
      border-bottom: 1px solid $line-gray;
      flex-direction: column;
      margin-bottom: 15px;
      flex-shrink: 0;
      width: 224px;
      height: 158px;
      background: rgba(248, 248, 248, 0.5);
      border-radius: 8px;
      margin: 0;
      padding: 32px 44px;

      @media (max-width: 600px) {
        width: 80vw;
        height: 62px;
        border-radius: 0;
        border-bottom: 1px solid #EBECED;
        ;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
      }
    }

    &__initial {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      width: 100%;

      @media (max-width: 600px) {
        width: auto;
        height: auto;
      }
    }

    &__flag {
      font-weight: bold;
      font-size: 11pt;
      color: $primary-color-darker;
    }

    &__number {
      font-size: 11pt;
      color: $card-gray;
    }



    &__button {
      padding: 10px 30px;
      color: $secondary-color;
      text-transform: uppercase;
      border: none;
      background: #fff;
      cursor: pointer;
      font-size: 9pt;
      letter-spacing: 1px;
      font-weight: bold;

      @media (max-width: 600px) {
        padding: 0 !important;
      }

      &:hover {
        color: $secondary-color-dark;
        transition: all 0.2s ease-in;
      }
    }
  }
}