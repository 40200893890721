.mobile-modal {
  @media (max-width: 600px) {
    top: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0 !important;
  }

  &__account {
    @media (max-width: 600px) {
      padding-top: 10vh !important;
    }
  }
  &__deposit {
    @media (max-width: 600px) {
    }
  }
  &__warning {
    @media (max-width: 600px) {
      padding-top: 10vh !important;
    }
  }

  &--configuration {
    @media (max-width: 600px) {
      zoom: 0.9;
      width: 100vw !important;
      margin-top: 30vh !important;
    }
  }
}
