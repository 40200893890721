.icon {
  &__login {
    fill: #006b9d;

    &__st3 {
      fill: #fff;
    }
  }
}

.login {
  &__container {
    background-color: $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__form {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;

      &__input {
        display: block;
        border-radius: 28px;
        border: solid 1px $light-gray;
        margin: 10px;
        width: 70%;
        padding: 15px;

        @media (max-width: 600px) {
          width: 85%;
        }

        &:focus {
          border-radius: 28px;
        }
      }

      &__password {
        position: relative;
        width: 70%;
        margin-top: 15px;
        margin-bottom: 15px;

        @media (max-width: 600px) {
          width: 85%;
        }

        &__input {
          display: block;
          width: 100%;
          border-radius: 28px;
          border: 1px solid $light-gray;
          padding: 15px;
          padding-right: 50px;
        }
      }

      &__links {
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 80%;
        margin: 15px;
        text-decoration: underline;
        font-size: 10pt;

        &__code {
          color: $secondary;
          cursor: pointer;

          &:hover {
            color: $secondary-hover;
            transition: all 0.2s ease-in;
          }
        }
      }

      &__button {
        flex: 1;
        border-radius: 23px;
        width: 55%;
        padding: 15px;
        color: $white;
        background-color: $primary;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        color: $white;
        border: none;
        box-shadow: none;
        margin: 20px 0 25px;
        opacity: 1;
        cursor: pointer;

        @media (max-width: 600px) {
          width: 85%;
        }

        &:hover {
          background: $primary-hover;
          transition: all 0.2s ease-in;
        }
      }

      &__ic-visibility {
        fill: $icon-gray;
        position: absolute;
        bottom: 13px;
        right: 30px;
      }
    }

    &__description {
      margin: 25px;

      &__text {
        font-weight: bold;
        font-size: 14pt;
        margin: 25px;
        color: $primary-hover;

        @media (max-width: 600px) {
          font-size: 11pt;
        }
      }
    }

    &__logo {
      text-align: center;
      margin-top: 25px;
      padding: 10px;
    }

    &__button {
      flex: 1;
      border-radius: 23px;
      width: 55%;
      padding: 15px;
      color: $white;
      background-color: $secondary;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      color: #ffffff;
      border: none;
      box-shadow: none;
      margin: 20px;
      margin-bottom: 40px;
      opacity: 1;
    }

    .button-shadow {
      width: 35%;
      box-shadow: 0px 10px 18px #00000029;
    }

    .container-button {
      width: 100%;
    }
  }
}