.cash-in-value {
    &__content {
        display: flex;
        flex-direction: column;
        box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
        border-radius: 8px;
        padding: 32px;

        width: 100%;
        margin-top: 24px;

        @media screen and (max-width: 600px) {
            align-items: center;
        }

        &__title {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $dark-gray;
            width: 100%;
        }

        &__description {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $dark-gray;
            width: 100%;

            b {
                color: $tertiary-color;
            }
        }

        &__cashout_form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 24px;

            &__input {
                display: flex;
                width: 100%;
                max-width: 320px;
                height: 60px;
                box-shadow: 2px 2px 12px rgba(11, 47, 99, 0.16);
                border: none;
                border-radius: 32px;
                outline: none;
                padding: 0 16px;

                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: $primary;
            }

            &__shortcuts {
                margin-top: 16px;
                display: flex;
                gap: 12px;

                &__button {
                    padding: 8px 16px;
                    border: 1px solid $primary;
                    border-radius: 32px;
                    background: transparent;
                    outline: none;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: $primary;
                    cursor: pointer;

                    &:hover {
                        border-color: $primary-hover;
                        color: $primary-hover;
                        font-weight: 600;
                    }
                }
            }

            &__error {
                color: $red;
                font-size: 14px;
                margin-top: 8px;
            }
        }

        &__button {
            padding: 8px 16px;
            background: $primary;
            color: $white;
            border-radius: 32px;
            outline: none;
            border: none;
            align-self: center;
            margin-top: 48px;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            cursor: pointer;

            &:disabled {
                background: $light-gray;
                cursor: not-allowed;
            }
        }
    }
}