.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.DayPicker-Months {
  font-family: Nunito;
}

.DayPicker-Months .DayPicker-Caption > div {
  font-size: 16pt;
  font-family: Nunito;
  font-weight: bold !important;
}

.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.DayPicker-Day--today {
  font-weight: bold;
  color: $primary-color-dark !important;
}

.action__buttons {
  margin-top: 20px;
  font-size: 10pt !important;
}

.btn__default {
  border: none;
  cursor: pointer;
  letter-spacing: 3.3px;
  padding: 10px 20px;
  margin: 0 7px;

  &__confirm {
    background: $secondary-color;
    color: white;
    border-radius: 20px;
  }

  &__cancel {
    background: none;
    color: $secondary-color;
    border-radius: 20px;
  }
}

.link {
  border: none;
  background: none;
  cursor: pointer;
  color: $secondary-color;
}

.date-picker {
  &__selected {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    border: 1px solid $light-gray;
    border-radius: 3px;
    padding: 7px;
    font-weight: bold;
  }
}
