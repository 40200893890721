.extract-page {
  display: flex;
  flex-direction: column;
  padding: 32px;
  position: relative;

  @media only screen and (max-width: 600px) {
    padding: 0;

    &__filters {
      padding: 16px;
    }
  }

  &__divider {
    display: flex;
    width: 100%;
    border: 1px dashed #EBECED;
    margin: 0 !important;
    padding: 0 !important;

    @media only screen and (min-width: 600px) {
      display: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__mobile {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #313234;
      display: none;
      padding: 16px;
      margin-bottom: 0;
      height: 40px !important;

      @media only screen and (max-width: 600px) {
        display: flex;
      }
    }

    &__menu {
      @media only screen and (max-width: 600px) {
        visibility: hidden;
        display: none;
      }

      &__button {
        background: transparent;
        border: none;
        border-bottom: 2px solid $gray-border;
        color: $primary-color;
        padding: 0 16px 8px;
        min-width: 120px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        text-align: center;

        &__active {
          color: $primary-color-dark;
          border-bottom: 2px solid $primary-color-dark;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 32px;
    border: 1px dashed $white2;
    border-radius: 8px;

    @media only screen and (max-width: 600px) {
      border: none;
      padding: 8px 32px;
      margin-top: 0;
    }
  }

  &__filters {
    position: relative;
    width: 100%;
    max-width: 200px;
    display: flex;
    justify-content: flex-end;

    &__menu {
      position: absolute;
      background-color: $white;
      top: calc(100% + 16px);
      right: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0;

      z-index: 999;
      border-radius: 8px;
      box-shadow: 4px 4px 80px rgba(11, 47, 99, 0.24);

      button {
        border: none;
        outline: none;
        background-color: transparent;

        display: flex;
        align-items: center;
        width: 100%;

        padding: 8px 16px;

        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: $dark-gray;

        svg {
          margin-right: 4px;
        }

        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }

      #mobile {
        display: none;

        @media only screen and (max-width: 600px) {
          display: flex;
        }
      }
    }
  }
}

.panel {
  &__head {
    width: 820px;
    margin: auto;
    padding: 15px 0;
    padding-left: 25px;

    &__title {
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 30px;
    }

    &__icon {
      fill: $secondary-color;
      margin-right: 10px;
    }

    &__back {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      display: inline-block;
      color: $secondary-color;
      cursor: pointer;

      &__text {
        padding-left: 10px;
        color: $secondary-color;

        &:hover {
          color: $secondary-color-dark;
          transition: all 0.2s ease-in;
        }
      }

      &:hover {
        color: $secondary-color-dark;
        transition: all 0.2s ease-in;
      }
    }
  }

  &__extract {
    padding-top: 20px;
    padding-bottom: 30px;

    &__list {
      background: #fff;
      border-top: 2px #c5c5c5 dashed;
      border-bottom: 2px #c5c5c5 dashed;
      padding-bottom: 40px;
      margin-bottom: 25px;

      @media (max-width: 600px) {
        padding-top: 15px;
      }
    }
  }

  &__container {
    padding: 25px;

    @media (max-width: 600px) {
      padding: 0;
    }

    &__title {
      color: $primary-color-darker;
      font-weight: bold;
      margin-bottom: 25px;

      &--mobile {
        margin: 0 10px 10px;
      }
    }

    &__operation {
      color: $primary-color-darker;
      font-size: 12pt;

      @media (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__footer {
      margin-top: 10px;
      margin-bottom: -20px;
    }

    &__info {
      &__title {
        letter-spacing: 3px;
        color: $login-small-text;
        font-size: 8pt;
      }

      &__description {
        color: $primary-color;
        font-size: 14pt;
        font-weight: bold;
      }

      &__text--small {
        color: $primary-color;
        font-size: 8pt;
        text-align: center;
      }

      &__text {
        color: $primary-color;
        font-size: 10pt;

        &__bank {
          margin-top: 7px;
          color: $primary-color;
          font-size: 10pt;
          line-height: 2pt;

          &--first {
            white-space: pre-wrap;
            line-height: 15pt;
          }
        }
      }
    }

    &__icon {
      fill: $login-small-text;
    }

    &__icon--green {
      fill: $green;
    }

    &__line {
      border-top: 2px dashed $line-gray;
    }

    &__text--footer {
      color: $green;
      margin-left: 15px;
      margin-top: 5px;
    }
  }
}

.selected {
  &__date {
    font-size: 10pt;
    font-weight: bold;
    padding-right: 20px;
    margin-top: 10px;
    color: $primary-color;
  }
}