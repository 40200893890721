.history {
  &__card {
    padding: 15px;

    &__canceled {
      margin-top: 15px;
      font-size: 1rem;
      color: $red;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &__title {
      font-size: 1.2rem;
      font-weight: bold;
      color: $primary-color;
    }

    &__header {
      border-bottom: 2px dashed $line-gray;
      padding: 15px 0 10px;

      &__number {
        text-transform: uppercase;
        letter-spacing: 1.3px;
        font-size: 0.75rem;
        margin: 5px 0;
        color: $light-gray;
      }

      &__description {
        text-transform: uppercase;
        letter-spacing: 1.3px;
        font-weight: bold;
        color: $primary-color;
      }
    }

    &__content {
      &__items {
        padding: 15px 0 10px;
        font-size: 0.8rem;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: $primary-color;
      }

      &__total {
        padding: 0 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-bottom: 2px dashed $line-gray;

        &__text {
          font-size: 0.8rem;
          color: $primary-color;
          letter-spacing: 1.3px;
          text-transform: uppercase;
        }

        &__value {
          color: $green;
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }

    &__payment {
      padding: 15px 0;

      &__icon {
        display: flex;
        justify-content: center;
        margin: 5px 0 15px;
      }

      &__status {
        margin: 5px 0;
        font-size: 0.75rem;
        color: $primary-color;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        font-weight: bold;

        &--yellow {
          color: $yellow;
        }

        &--light-blue {
          color: $light-blue;
        }

        &--red {
          color: $red;
        }

        &--green {
          color: $green;
        }

        &--blue {
          color: $secondary-color-dark;
        }
      }

      &__accepted-methods {
        font-size: 0.75rem;
        color: $primary-color;
        letter-spacing: 1.3px;
        text-transform: uppercase;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__creation {
        display: flex;
        align-items: baseline;
        justify-content: center;

        &__text {
          color: $primary-color;
          font-size: 0.75rem;
          letter-spacing: 1.3px;
          margin-right: 5px;
          text-transform: uppercase;
        }

        &__date {
          font-size: 1rem;
          font-weight: bold;
          color: $primary-color;
        }
      }
    }
  }
}

.order {
  &__item {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__description {
      max-width: 70%;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      &__quantity {
        margin-right: 15px;
      }
    }

    &__price {
      font-weight: bold;
    }
  }
}

.payment-method {
  &__icon {
    &-hidden {
      display: none;
    }
  }
}

.uppercase-text {
  text-transform: uppercase;
}
