.update-modal {
  background: $background;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 360px;

  display: flex;
  flex-direction: column;

  &__code {
    margin-top: 16px;
  }

  &__deleteModal {
    height: 521px;
    padding: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: $background;
    width: 420px;
    text-align: start;
    font-family: 'Inter';

    h5 {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &__text {
      color:  #66696F;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__textContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__highlight{
      font-weight: 700;
    }
    &__buttonContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }

  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    h5 {
      font-weight: 800;
      font-size: 18px;
      line-height: 23px;
      margin: 0;
    }
  }

  &__close-button {
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      fill: $text-gray;
    }
  }

  &__title {
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: $text-gray;
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 16px;

    img {
      grid-area: icon;
      width: 54px;
      height: 54px;
    }

    &__flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 8px;
      flex: 1;

      &__text {
        text-align: left;
        margin: 0;

        &:first-of-type {
          grid-area: title;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: $primary;
        }

        &:last-of-type {
          grid-area: description;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: $text-gray;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 16px;
    width: 60%;

    button {
      &:last-of-type {
        margin-top: 8px;
      }
    }
  }
}

.two-factor-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $primary;
    text-align: center;
  }

  input {
    border: none;
    border-bottom: 1px solid $primary;
    font-size: 12pt;
    letter-spacing: 18px;
    width: 65%;
    padding: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  &__resend-code {
    margin-top: 16px;
  }

  &__error {
    font-size: 12px !important;
    color: $error !important;
    margin-top: 8px;
    margin-bottom: 0 !important;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;
  }
}

.update-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.update-address {
  background: $background;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 360px;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: $text-gray;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    &__flex {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 8px;

      &__cep {
        width: 40%;
      }

      &__district {
        width: 60%;
      }

      &__street {
        width: 70%;
      }

      &__number {
        width: 30%;
      }

      &:last-of-type {
        margin-top: 16px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width:600px) {
    &__form {
      &__flex {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        gap: 0;

        &__cep,
        &__district,
        &__street,
        &__number {
          width: 100%;
        }

        column-gap: 24px;
      }
    }
  }
}