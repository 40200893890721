.empty-state {
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid $white2;
    padding: 24px;
    width: 100%;
    margin-top: 16px;

    &__content {
        margin-left: 32px;

        h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: $text-dark-gray;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: $text-gray;
        }

        button {
            background: transparent;
            border: none;
            text-align: left;
            padding: 0;
            display: flex;
            align-items: center;

            color: $primary;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
                color: $primary-hover;
            }
        }
    }

    @media (max-width:600px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: none;

        img {
            margin-bottom: 8px;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;

            h3 {
                display: none;
            }

            button {
                text-align: center;
                border-radius: 8px;
                padding: 8px;
                border: 1px solid $primary;

                svg {
                    display: none;
                }
            }
        }
    }
}