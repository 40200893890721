.small {
  &__card {

     &__invisible {
        position: relative;

        &__question {
          width: 15px;
          height: 15px;
          cursor: pointer;
          margin-bottom: 3px;
          margin-left: 3px;
          fill: currentColor !important;
        }

        &__info {
          font-family: Tahoma, sans-serif;
          width: 280px;
          background: #000000;
          letter-spacing: 0px;
          padding: 10px;
          border-radius: 4px;
          font-size: 13px;
          transition: all ease-in 0.2s;
          visibility: hidden;
          position: absolute;
          top: calc(50% + 15px);
          left: 50%;
          transform: translateX(-50%);
          color: #FFFFFF;
          text-align: left;

          &:before {
            content: '';
            border-style: solid;
            border-color: #000000 transparent;
            border-width: 0px 6px 6px 6px;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
          }

          &__text {
             &__two {
            font-size: 12px;
            margin-top: 5px;
           }
          }
        
        }

        &:hover &__info {
          opacity: 1;
          visibility: visible;
        }
       
      }
    &__text {
      margin: 2px 0;
      letter-spacing: 3.3px;
      font-size: 0.6rem;
      color: $gray-text-color;
      
      @media (max-width: 1600px) {
        letter-spacing: 1.0px;
        font-size: 0.6rem;
      }
    }

    &__balance {
      margin: 2px 0;
      letter-spacing: 3.3px;
      font-size: 0.6rem;
      font-weight: bold;

      @media (max-width: 1600px) {
        letter-spacing: 1.0px;
        font-size: 0.7rem;
      }
    }

    &__value {
      margin: 2px 0;
      font-size: 0.8rem;

      &-green {
        font-weight: bold;
        margin: 2px 0;
        color: $green;
        font-size: 1.0rem;
      }
    }
  }
}

.mobile {
  &__balance {
    &__wrapper {
      margin: 0 0 15px;
      box-shadow: 0px 7px 2px -2px#00000029;
      padding-bottom: 7px;
    }

    &__text {
      font-weight: bold;
      letter-spacing: 1.1px;
      font-size: 0.7rem;
      color: $primary-color;
      text-transform: uppercase;
    }

    &__value {
      color: $primary-color;
      font-size: 1.2rem;
      font-weight: bold;

      &--green {
        color: $green;
      }
    }
  }
}
