// base
.transparent-button {
  background-color: transparent;
  border: none;
  outline: 0 !important;
  padding: 0;
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

button:focus,
a:focus {
  outline: none;
}

.with-transition {
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.page-wrapper {
  height: 100%;
}

.Toastify__toast--warning {
  background-color: #ff6600;
}

.popup-content {
  width: auto !important;
  border-radius: 16px;
  background: transparent;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}