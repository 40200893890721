.empty-extract {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &__text {
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: $dark-gray;
    }
}