.panel-configuration {
  padding: $padding-container;
  width: 100%;
  max-width: 700px;
  align-self: center;

  &__title {
    font-weight: 800;
    font-size: 24px;
    color: $text-dark-gray;
  }

  &__section {
    margin-top: 32px;


    &__header {
      display: flex;
      justify-content: space-between;

      &__title {
        font-weight: 600;
        font-size: 20px;
        color: $text-dark-gray;
      }

      &__button {
        background: transparent;
        border: none;
        font-weight: 700;
        font-size: 16px;
        color: $tertiary;

        cursor: pointer;

        &:hover {
          color: $tertiary-hover;
        }
      }
    }

    &__content {
      padding: 16px $padding-container;
      border: 1px solid $border;
      border-radius: $radius;
      margin-top: 16px;

      box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);

      hr {
        width: calc(100% + 64px);
        margin-left: -32px;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border: none;
        background: transparent;
        text-align: left;
        width: 100%;

        &__info {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: space-between;

          span,
          p {
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            color: $text-dark-gray;
          }

          span {
            font-weight: 700;
            width: 40%;
          }

          p {
            font-weight: 400;
            width: 50%;
          }
        }

        cursor: pointer;

        svg {
          margin-left: 16px;
          width: 32px;
          height: 32px;
          fill: $text-gray;
        }
      }

      .hidden {
        visibility: hidden;
      }
    }

    &__address {
      padding: 16px $padding-container;
      border: 1px solid $border;
      border-radius: $radius;
      margin-top: 16px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      background: transparent;

      cursor: pointer;

      div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;

        span,
        p {
          margin: 0;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
        }

        span {
          font-weight: 700;
          color: $text-opaque-gray;
        }

        p {
          margin-top: 4px;
          font-weight: 400;
          color: $text-dark-gray;
        }
      }

      svg {
        margin-left: 16px;
        width: 32px;
        height: 32px;
        fill: $text-gray;
      }
    }
  }

  &__devices {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width:600px) {
    &__section {
      &__content {
        padding: 16px;

        hr {
          width: calc(100% + 32px);
          margin-left: -16px;
        }


        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &__info {
            flex-direction: column;
            flex: 1;
            align-items: flex-start;

            span,
            p {
              width: auto;
              text-align: left;
            }

            p {
              font-size: 100%;
            }
          }
        }
      }

      &__address {
        padding: 16px;
      }
    }
  }

  &__devices {
    gap: 16px;
  }
}