.delete-account {
    
 &__subtitle {
    color:  #66696F;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
 }
 &__container {
    display: flex;
    align-items: center;
 }

 &__label {
    margin: 0px;
    margin-left: 8px;
    color: var(--text-medium, #66696F);
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

 }
 &__checkbox {
    width: 16px;
    height: 16px;
    accent-color: #A900E4;
    outline: #A900E4;
 }

 &__button {
    display: flex;
    width: 213px;
    height: 40px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--functional-primary-700, #A900E4);
    font-family: 'Manrope';
    color: #fff;
    text-transform: none!important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
 }

 &__buttonClose {
    height: 40px;
    padding: 8px 12px;
    background: transparent;
    color: #73757D;
 }

 &__text {
    color: var(--text-medium, #66696F);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
 }
}