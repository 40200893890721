.cashout_details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  padding: 32px;
  border: 1px solid $border;
  border-radius: $radius;

  &__back_button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: $primary;
    font-weight: 600;
    cursor: pointer;
    margin: 0;
    padding: 0;

    svg {
      margin-right: 8px;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__title {
      font-size: 20px;
      font-weight: 400;
      color: $text-gray;
    }

    &__value {
      font-size: 24px;
      font-size: 800;
      color: $text-dark-gray;
    }
  }

  &__account {
    &__title {
      color: $text-dark-gray;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }

    &__value {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $text-gray;
    }
  }

  &__fee {
    &__information {
      display: flex;
      justify-content: space-between;

      &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $text-gray;
        margin: 0;
      }

      &__value {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $text-dark-gray;
      }
    }
  }

  &__divider {
    border: 1px dashed $border;
    width: calc(100% + 64px);
    margin: 16px 0 16px 0;
    align-self: center;
  }

  &__update {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    svg {
      display: none;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &__center {
      align-self: center;
      gap: 24px;
    }
  }

  &__switch_account_button {
    border: none;
    background: transparent;
    color: $primary;
    letter-spacing: 3.3px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    align-self: center;
    column-gap: 24px;
  }

  @media screen and (max-width: 600px) {
    align-self: flex-start;
    padding: 0 16px !important;
    border: none;

    &__divider {
      width: 100%;
    }

    &__update {
      button {
        display: none;
      }

      svg {
        display: flex;
      }
    }

    &__header {
      &__title {
        font-weight: 600;
        font-size: 18px;
      }

      &__value {
        color: $primary;
        font-size: 18px;
        font-weight: 600;
      }
    }

    &__fee {
      &__information {

        &__text {
          font-size: 12px;
        }

        &__value {
          font-size: 14px;
        }
      }
    }

    &__footer {
      column-gap: 0;
      flex-direction: column-reverse;
      row-gap: 24px;
      margin-top: 16px;
    }
  }

}

.btn {
  &__back {
    padding-left: 10px;
    color: $primary;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      color: $primary-hover;
    }
  }

  &__link {
    cursor: pointer;
    color: $primary;
    letter-spacing: 3.3px;
    border: none;
    background: transparent;
    text-transform: uppercase;
    font-size: 9pt;

    &:hover {
      color: $primary-hover;
      transition: all ease-in 0.2s;
    }
  }
}

.cash-out {
  &__input {
    &__money {
      margin: 20px 0 0;
    }
  }

  &__ic_keyboard_backspace {
    margin-left: 10px;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__account {
    margin: 10px 0 0;
  }

  &__button {
    margin: 20px;

    &__add {
      font-size: 10pt;
      cursor: pointer;
      color: $secondary-color;
      border: solid 1px $secondary-color;
      background: #fff;
      border-radius: 22px;
      padding: 7px;
      margin: 0 5px;

      &:hover {
        color: #fff;
        background: $secondary-color;
        transition: all 0.2s ease-in;
      }

      span {
        padding: 5px;
      }
    }

    &__submit {
      font-weight: bold;
      cursor: pointer;
      color: #fff;
      border: none;
      background: $line-gray;
      border-radius: 22px;
      padding: 10px 20px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &--enable {
        background: $secondary-color;
        transition: all 0.2s ease-in;
      }
    }
  }

  &__outter {
    margin: 20px 0;
    width: 100%;

    @media (max-width: 600px) {
      margin: 0;
    }
  }

  &__wrapper {
    padding: 10px 15px;

    &--confirmation {
      padding: 10px 30px 25px;
    }

    &--confirmation-header {
      padding: 25px 30px 10px;

      @media (max-width: 600px) {
        padding: 20px 0px 0;
      }
    }
  }

  &__title {
    color: $primary;
    font-size: 14pt;
  }

  &__balance {
    color: $primary;
    margin: 4px 0 20px;

    &__value {
      color: $primary;
    }
  }

  &__confirmation {
    &__title {
      color: $primary;
      font-size: 15pt;
      font-weight: bold;
      margin: 15px 15px 15px;

      @media (max-width: 600px) {
        margin-top: 5px;
        padding: 15px;
      }
    }

    &__text {
      padding: 15px 20px 2px;
      color: $primary;

      @media (max-width: 600px) {
        margin: 0;
        padding: 15px;
      }
    }

    &__value {
      color: $primary;
      font-size: 18pt;
      font-weight: bold;
      padding: 15px 20px 15px;

      &--small {
        padding: 15px 20px 2px;
        font-size: 12pt;

        @media (max-width: 600px) {
          margin: 0;
          padding: 15px;
        }
      }
    }

    &__user {
      border-top: 2px dashed #c5c5c5;
      border-bottom: 2px dashed #c5c5c5;
      padding: 30px 50px 5px;

      @media (max-width: 600px) {
        padding: 30px 15px 5px;
      }
    }
  }

  &__receipt {
    &__confirmation {
      margin-bottom: 25px;

      @media (max-width: 600px) {
        margin: 20px 0;
      }
    }
  }
}

.icon {
  &__back {
    cursor: pointer;
  }
}

.btn {
  &__text {
    border: none;
    background: none;
    padding: 0;
    color: $primary;
    cursor: pointer;
    margin: 7px 0;

    &:hover {
      transition: all ease-in 0.2s;
      color: $primary-hover;
    }
  }
}

.account {
  &__item {
    &__button {
      color: $primary !important;
    }
  }

  &__select {
    &__item {
      &__label {
        border: 1px solid transparent;
        background: none;
        display: inline-block;
        cursor: pointer;
      }

      &__wrapper {
        border-bottom: 1px solid $line-gray;
        margin-bottom: 15px;
        padding: 10px 0;

        input {
          margin-top: 6px;
        }
      }

      &__radio {
        cursor: pointer;
        fill: $primary;

        &:hover {
          fill: $primary-hover;
          transition: all ease-in 0.2s;
        }
      }

      &__name {
        display: flex;
        font-weight: regular;
        text-transform: uppercase;
        color: $primary;
        padding-bottom: 4px;
      }

      &__bank {
        display: flex;
        margin: 5px 0;
        text-align: left;
      }

      &__agency {
        display: flex;
        margin: 5px 0;
      }

      &__number {
        display: flex;
        margin: 5px 0;
      }
    }
  }
}

.no-account {
  &__modal {
    &__title {
      font-weight: bold;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      justify-content: center;
      border-bottom: 1px solid $line-gray;
      padding-bottom: 15px;
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-bottom: 15px;
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      &--cancel {
        cursor: pointer;
        border: none;
        background: none;
        color: $primary;

        &:hover {
          color: $primary-hover;
          transition: ease-in 0.2s;
        }
      }

      &--confirm {
        cursor: pointer;
      }
    }
  }
}

.no-background {
  background: #f6f6f6 !important;
}

.mobile-receipt {
  padding-top: 20px !important;
}

.line-dashed {
  border-top: 2px dashed #c5c5c5;
  width: 100%;
  left: 0px;
  margin: 0px;
  position: absolute;
}