.tab {
  &__wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  &--mobile {
    width: 100vw;
    padding: 0 5px;
  }
}

.btn {
  &__tab {
    padding: 5px;
    font-size: 0.7rem;
    border: none;
    background: none;
    color: $gray-text-color;
    letter-spacing: 3.3px;
    cursor: pointer;
    text-transform: uppercase;

    @media (max-width: 600px) {
      font-size: 0.6rem;
    }

    &--active {
      color: $secondary-color;
      border-bottom: 1px solid $secondary-color;

      @media (max-width: 600px) {
        font-weight: bold;
      }
    }

    &:hover {
      color: $secondary-color;
      transition: all 0.2s ease-in;
    }
  }
}
