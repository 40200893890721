.cash-button {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    border-radius: 22px;
    box-shadow: 0px 10px 22px #00000024;
    width: 320px;
    height: 60px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 999;
    background: linear-gradient(90deg, $secondary 50%, #fff 50%);
  }

  &__text {
    font-size: 8pt;
    line-height: 15pt;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: $secondary;
    display: block;

    &--white {
      color: #fff;
    }
  }

  &__cash-in {}

  &__cash-out {}
}