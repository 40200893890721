.day {
  &__wrapper {
    width: 400px;
    border-radius: 22px;
    border: none;
    background-color: #fff;
    box-shadow: 0px 10px 32px #00000024;
    margin-top: 20px;
    padding: 10px;

    @media (max-width: 600px) {
      padding: 7px;
      margin-top: 7px;
    }
  }

  &__date {
    font-size: 16px;
    font-weight: bold;
    color: $primary-color-darker;
  }

  &__description {
    color: $gray-text-color;
    padding: 0 10px;
  }

  &__balance {
    font-size: 16px;
    color: $green;
    margin-right: 15px;

    &__text {
      color: $gray-text-color;
    }

    &__value {
      color: $primary-color;
      font-size: 1rem;

      &--green {
        color: $green;
      }
    }
  }
}
