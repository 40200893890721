// Variables
$font-size: 1.4rem !default;
$sidebar-font-size: 1.4rem !default;
$footer-font-size: 1.3rem !default;
$input-font-size: 1.4rem !default;
$button-font-size: 1.4rem !default;

$primary-color: #BE00FF !default;
$primary-color-dark: #a804df !default;
$primary-color-darker: #9300C5 !default;
$secondary-color: #4197e3 !default;
$secondary-color-dark: #069 !default;
$link-color: $primary-color !default;
$gray-text-color: #6d7380;
$card-gray: #6c707a;
$secondary-light-gray: #e4e4e4;
$configuration-indicator: #a7b9c5;
$text-gray: #66696F;

$white: #FFFFFF;
$dark-white: #F8F8F8;
$darkest-white: #e8ecf0;
$opacity-background: #F9F1FF;
$white2: #EBECED;
$color-title-item: #62697a;
$light-gray: #aeb2bb;
$line-gray: #c9c9c9;
$dashed-gray: #c5c5c5;
$icon-gray: #d4d4d4;
$line-header-login: #4f576a;
$paragraph-color: #11585b !default;
$login-small-text: #6d7380;
$gray: #c5d1dd;
$dark-gray: #666666;
$blue-primary: #4197e2;
$dark-blue: #55708B;

$gray-border: #BEC5CB !default;
$blue-border: #0095c8 !default;

$water-blue: #abecef !default;

$tertiary-color: #FA6400 !default;

$border-radius: 0 !default;

$dark-gray: #d1d1d1 !default;
$darker-gray: #707070 !default;

$yellow: #e6ab33 !default;
$red: #be3c3c !default;
$light-blue: #00ced1 !default;

$body-text: #7a7d82 !default;
$light-text: #8a8a8a !default;
$headings-text: #21293c !default;

$primary: #A900E4;
$primary-hover: #9a04cc;
$secondary: #9300C5;
$secondary-hover: #700594;
$tertiary: #FA6400;
$tertiary-hover: #9c4002;
$background: #FFFFFF;
$background-secondary: #FAFAFA;

$error: #BD3939;
$green: #59AC7C !default;

$white3: #FAFAFA;

$text-gray: #73757D;
// $text-dark-gray: #131415;
$text-dark-gray: #313234;
$text-opaque-gray: #66696F;
$border: #EBECED;

$radius: 8px;
$padding-container: 32px;

$font-family: "Open Sans", sans-serif !default;
$second-font-family: "Oswald", sans-serif !default;

$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xlst: 1920px,
);

$max-breakpoints: (
  xs: 479px,
  sm: 575px,
  md: 767px,
  lg: 991px,
  xl: 1199px,
  xxl: 1599px,
);