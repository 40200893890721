.transaction_card {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    border-radius: 50%;

    background: #f8f8f8;

    svg {
      color: #9300C5;
    }
  }

  &__content {
    margin: 0 16px;
    flex: 1;
    // font-size: 100%;

    h6 {
      font-weight: 600;
      font-size: 16px;
      margin: 0 !important;
      line-height: 18px;
      color: #313234 !important;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #66696f;
      margin: 0 !important;
    }
  }

  &__date {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #73757d;
  }
}
