.flipped-card {
    width: 258px;
    height: 160px;
    perspective: 700px;

    &__empty {
        width: 40px;
        height: 40px;
    }

    &__front,
    &__back {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        transition: 700ms;
    }

    &__front {
        background: linear-gradient(220.03deg, #BE00FF 1.2%, #540072 85.13%);
        z-index: 1;

        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            width: 40px;
            align-self: flex-end;
        }


        &__content {
            &__number {
                font-weight: 400;
                font-size: 18px;
                line-height: 25px;
                color: $white3;
                margin: 0;
            }

            &__data {
                margin-top: 8px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: $white3;
                    margin: 0;
                    text-align: left;

                    &:first-of-type {
                        flex: 1;
                        padding-right: 16px;
                    }

                    &:last-of-type {
                        text-align: right;
                    }
                }
            }
        }
    }

    &__back {
        background: linear-gradient(73.96deg, #540072 16.57%, #A900E4 98.33%);
        transform: rotateY(-180deg);
        backface-visibility: hidden;

        display: flex;
        flex-direction: column;

        hr {
            width: 100%;
            height: 34px;
            background: $text-gray;
        }

        p {
            text-align: right;
            color: $white3;
            font-weight: 400;
            font-size: 20px;
            margin-right: 26px;
        }
    }

    &__flip &__front {
        transform: rotateY(180deg);
        z-index: 0;
    }

    &__flip &__back {
        transform: rotateY(0);
        z-index: 1;
    }
}