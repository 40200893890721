.header-right-link {
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 0;
  display: inline-block;
  outline: 0 !important;

  &__wrapper {
    padding-left: 65px;
  }

  &__icon {

  }

  &__label {
    color: #fff;
    padding-left: 15px;
    position: relative;
    top: 2px;
  }
}