.cashout-modal-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    padding: 24px;
    width: 100%;
    max-width: 420px;
    margin: 0 !important;

    text-align: center;

    h4 {
        font-weight: 800;
        font-size: 18px;
        line-height: 23px;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin: 0;
        color: $text-gray;
    }

    div {
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 24px;
    }
}