.dropdown {
  display: flex;
  align-items: center;

  &__label {
    color: $gray-text-color;
  }

  &__select {
    flex-grow: 1;

    svg {
      color: $primary !important;
      fill: $primary !important;
    }
  }
}