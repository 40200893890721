// layout

html,
body {
  font-family: 'Manrope', sans-serif;
  background-color: #ffff;
  height: 100%;

  @media only screen and (width: 1366),
  screen and (height: 768px) {
    zoom: 0.8;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

h1 {
  font-weight: 800;
  font-size: 30px;
  letter-spacing: -0.02em;
  font-style: normal;
}

h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  letter-spacing: -0.02em;
}

h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
}

h4 {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
}

h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
}

h6 {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
}