.device-item {
  width: 100%;
  background: $background-secondary;
  border-radius: $radius;
  padding: 32px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      margin: 0;
    }

    svg {
      cursor: pointer;
      font-size: 20px;
      color: $primary;
    }
  }

  hr {
    display: none;
  }

  &__content {
    margin-top: 16px;

    p {
      margin: 0;
    }
  }

  @media (max-width:600px) {
    background: $background;

    hr {
      display: flex;
      width: 100%;
      border: 1px dashed $border;
      margin: 16px 0;
    }
  }
}