.credit {
    &__container{
        padding: 38px 24px;
    }
    &__filter {
        display: flex;
        justify-content: space-between;
    }
    &__search {
        width: 343px;
        height: 39px;
        border-radius: 100px;
        padding: 4px 16px 4px 24px;
        border: none;
        background-color: #FAFAFA;

    }
    &__icon {
        background: url("data:image/svg+xml;charset=utf-8,<svg id='search-24px' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 75 75'><path id='Caminho_1' data-name='Caminho 1' d='M51.949,46.075H48.856l-1.1-1.057a25.492,25.492,0,1,0-2.741,2.741l1.057,1.1v3.094L65.655,71.49l5.835-5.835Zm-23.5,0A17.622,17.622,0,1,1,46.075,28.454,17.6,17.6,0,0,1,28.454,46.075Z' transform='translate(0 0)'/><path id='Caminho_2' data-name='Caminho 2' d='M0,0H75V75H0Z' fill='none'/></svg>") no-repeat right;
        background-size: 20px;
        background-color: #FAFAFA;
        background-position: right 5% bottom 50%;
    }
    &__content {
        margin-top: 34px;
    }
    &__title { 
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #313234;
    }
    &__disclaimer { 
        height: 70px;
        margin-top: 16px;
        width: 100%;
        border: 1px solid #EBECED;
        border-radius: 4px;
        border-left: 9px solid #545B67;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media (max-width:600px) {
            height: 184px;
            padding: 16px 24px;
            
        }
    }
    &__medium-text {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #66696F;
        margin: 0px;
    }

    &__cancelReason {
        width: 100%;
        height: 119px;
        background: #FFFFFF;
        border: 1px solid #BEC5CB;
        border-radius: 20px;
        padding: 10px;
    }

    &__iconContainer {
         @media (max-width:600px) {
            display: flex;
            align-self: baseline;
        }
         
    }

    &__item {
        border: 1px solid #EBECED;
        border-radius: 8px;
        padding: 32px;
        margin-top: 16px;
    }

    &__item-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #313234;
    }
    &__divider {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 14px;
        border-bottom: 1px solid #EBECED;
    }

    &__cancel-button {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #66696F;
        border: none;
        padding: 0px;
        background: transparent;
        cursor: pointer;
        @media (max-width:600px) {
            
            margin-top: 20px;
        }
    }

    &__finalDisclaimer {
        margin-top: 40px;
        width: 880px;
        @media (max-width:600px) {
            width: auto;
        }
    }

    &__filtersMobile {
        max-width: none;
    }

    &__emptyStatus {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #BEC5CB;
    }

    &__emptyStateText {
        display: flex;
        flex-direction: column; 
        justify-content: space-evenly; 
        width: 549px;
        @media (max-width:600px) {
            width: auto;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            text-align: center;
         }
    }
    &__emptyState {
        display: flex;
        margin-top: 20px;
        justify-content: space-evenly;
        @media (max-width:600px) {
           flex-direction: column;
           align-items: center;
        }
    }

    &__cancelText { 
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #BD3939
    }
    &__modal {
        display: flex;
        position: relative;
        height: 100%;
        flex-direction: column;
        padding: 34px;
        align-items: start;
        justify-content: space-between;
    }
    &__purpleButton {
        background: #A900E4;
        box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
        border-radius: 8px;
        border: none;
        height: 38px;
        color: #FFFFFF;
        cursor: pointer;
    }

}