.deposit_ted {
  border: 1px solid #EBECED;
  box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
  border-radius: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &__data {
    color: $text-gray;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__information {
    &__title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #313234;
      text-transform: uppercase;
    }

    &__list {
      &__item {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #66696F;
        margin-top: 8px;
      }
    }
  }

  hr {
    border-style: dashed;
    width: calc(100% + 64px);
    margin-left: -32px;
  }

  &__back-button {
    display: flex;
    width: 100%;
    justify-content: center;
    text-decoration: none !important;

    button {
      box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
      border-radius: 32px;
      background: $primary-color;
      border: none;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $white;
      text-transform: uppercase;
      padding: 8px;

      width: 100%;
      max-width: 300px;
      cursor: pointer;
    }
  }

  &__change-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #BE00FF;
    background: none;
    border: none;
    display: flex;
    cursor: pointer;

    &:hover {
      color: $primary-color-dark;
    }
  }

  &__continue-button {
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
    border-radius: 32px;
    background: $primary-color;
    border: none;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    text-transform: uppercase;
    padding: 8px;

    width: 100%;
    max-width: 300px;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  @media (max-width:600px) {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;

    &__footer {
      flex-direction: column;

      button {
        &:last-child {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }
}

.ted-information {
  margin-top: 16px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #313234;
    text-transform: uppercase;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #66696F;
    margin: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.ted {
  &__header {
    color: $primary-color-darker;
    font-size: 12pt;
    border-bottom: 2px dashed $dashed-gray;
    margin: 30px 50px 0px;
    padding: 0 30px;

    &__invisible {
      position: relative;
      left: 25%;

      @media (max-width: 500px) {
        left: 10%;
      }

      &__question {
        width: 18px;
        height: 18px;
        cursor: pointer;
        margin-bottom: 5px;
      }

      &__info {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        width: 280px;
        background: #000000;
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        transition: all ease-in 0.2s;
        visibility: hidden;
        position: absolute;
        top: calc(50% + 20px);
        left: 95%;
        transform: translateX(-50%);
        color: #FFFFFF;
        text-align: left;

        @media (max-width: 500px) {
          top: calc(-250% + 20px);
          left: -850%;
        }

        &:before {
          content: '';
          border-style: solid;
          border-color: #000000 transparent;
          border-width: 0px 6px 6px 6px;
          position: absolute;
          bottom: 100%;
          left: 47%;
          transform: translateX(-50%);

          @media (max-width: 500px) {
            border: 10px solid transparent;
            border-left-color: #000000;
            bottom: 60%;
            left: 103%;
          }
        }

        &__title {
          &__text {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }

      &:hover &__info {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
      }
    }
  }


  &__footer {
    margin: 15px 50px 20px;
    padding: 0 30px;
    word-break: normal;



    &__text {
      &--small {
        margin-bottom: 20px;
        text-align: center;
        word-break: normal;
      }
    }
  }

  &__content {
    margin: 15px 50px 0px;
    padding: 0 30px;
    border-bottom: 2px dashed $dashed-gray;


    @media (max-width: 600px) {
      margin: 0;
      padding: 15px;
    }

    &__title {
      margin-bottom: 5px;
      font-size: 0.7em;
      font-weight: bold;
      display: block;
      color: $secondary-color;
      letter-spacing: 3.3px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &__ob-text {
      margin-bottom: 14px;
      display: block;
      color: $primary-color-darker;
      width: 100%;
      border: none;
      padding: 7px 0;
      outline: none;
      transition: none;
      max-width: 100%;
    }

    &__list {
      // list-style: none;
      padding-left: 0;
    }

    &__list li {
      padding-top: 0.3rem;
      font-size: 0.7rem;
    }

    &__list li::before {
      content: attr(dataicon);
    }

    &__text {
      margin-bottom: 14px;
      display: block;
      color: $primary-color-darker;
      width: 100%;
      border: none;
      outline: none;
      transition: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}

.copy__icon {
  margin-left: 10px;
  cursor: pointer;

  svg {
    &:hover {
      fill: $secondary-color-dark;
      transition: all 0.2s ease-in;
    }
  }
}

.right-logo {
  position: absolute;
  right: 20px;
  top: 20px;
}

.ghost-input {
  width: 90px;
  border: none;
  outline: none;

  &--document {
    width: 200px;
  }

  &--big {
    width: 80%;
    resize: none;
  }
}