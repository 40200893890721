.institutional {
  &__section {
    padding: 70px 0;
    text-align: center;
    background: #fff;

    @media (max-width: 1024px) {
      padding: 40px 0 0;
    }

    &--background {
      margin-top: -183px;
      background: #f9f9f9;
    }

    &--blue {
      background: #d9eafa;
    }

    &__title {
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 50px;
      color: $primary-color;

      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
    }
  }
}

.utilization {
  &__create-button {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  &__steps {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    &__single {
      max-width: 15vw;

      @media (max-width: 1024px) {
        max-width: 100vw;
        border: 1px solid $primary-color;
        margin: 35px 15px;
        padding: 15px;
        border-radius: 7px;
        position: relative;
      }

      &__number {
        color: #4197e3;
        font-weight: bold;
        font-size: 2rem;
        border-radius: 50%;
        position: absolute;
        margin-top: 15px;
      }

      &__description {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}

.advantages {
  &__section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &__image {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 500px;
      position: relative;
      overflow: hidden;
      top: -75px;

      &__single {}

      &--placeholder {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background: #d9eafa;
        border: 1px solid #d9eafa;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-bottom: 140px;

      &__single {
        display: flex;
        margin: 30px 0;

        @media (max-width: 1024px) {
          padding: 0 30px;
        }

        &__image {
          width: 50px;

          @media (max-width: 1024px) {
            width: 50%;
          }
        }

        &__content {
          text-align: left;
          margin-left: 30px;

          &__description {
            width: 70%;
            margin-bottom: 10px;
            color: #6d7380;

            @media (max-width: 1024px) {
              width: 100%;
            }
          }

          &__link {
            margin-left: 5px;
            width: 150px;
            padding-top: 4px;
            color: #0095c8;
            border-top: 2px solid #d9eafa;

            &:hover {
              color: $primary-color;
              transition: ease-in 0.2s;
            }
          }
        }
      }
    }
  }
}