.configuration {


  &__mobile {
    &__wrapper {
       padding: 22px;
    }
  }

  &__head {

    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #313234;
    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__wrapper {
    background: rgba(248, 248, 248, 0.5) !important;
    border-radius: 8px;
    padding: 32px;
  }

  &__card {
    &__info {
      display: flex;
    }
    &__wrapper {
      @media(max-width: 600px){
        margin-bottom: 16px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 15pt;
      color: $primary-color-darker;

      @media (max-width: 600px) {
        font-size: 13pt;
      }
    }

    &__data {
      color: $primary-color-darker;
    }

    &__indicator {
      color: $configuration-indicator;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 8pt;
    }

    &__line {
      display: block;
      font-size: 11pt;
      color: #28334a;
    }

    &__button {
      color: #FF7D25;
      border: none;
      cursor: pointer;
      font-size: 700;
      font-weight: bold;
      background: transparent;

      &:hover {
        color: $primary-color;
        transition: all 0.2s ease-in;
      }
    }
  }
}

.btn {
  &__add {
    &__document {
      position: relative;
      padding: 50px 100px;
      border: dashed 2px $secondary-color;
      color: $secondary-color;
      cursor: pointer;

      background: transparent;
      border-radius: 5px;

      &__text {
        white-space: nowrap;
        margin-top: 10px;
        position: relative;
        font-weight: bold;
      }

      svg {
        position: relative;
      }

      &__circle {
        position: absolute;
        top: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transition: all 350ms ease 0ms;
      }

      &:hover &__circle {
        background: $white;
        opacity: 80%;
        border-radius: 5px;
        transform: scale(1);
        transition: all 350ms cubic-bezier(1, 1, 1, 1) 0ms;
      }

      @media all and (max-width: 1440px) {
        width: 303px;
        height: 154px;
      }
      &__wrapper {
        margin-top: 30px;
      }
    }
  }
}

.user {
  &__information {
    &__name {
      margin: 15px 0 7px;
      color: $primary-color-darker;
      font-size: 14pt;

      svg {
        cursor: pointer;
        fill: $secondary-color;
        margin-left: 25px;

        &:hover {
          fill: $secondary-color-dark;
          transition: all 0.2s ease-in;
        }
      }

      @media (max-width: 600px) {
        margin: 0.5rem 1rem 1.5rem;
      }
    }

    &__document {
      margin-bottom: 25px;
      font-size: 12pt;
      &--green {
        color: $green;
      }
      &--red {
        color: $red;
      }
    }

    &__photo {
      &__button {
        display: none;
      }

      &__icon {
        cursor: pointer;
        background: #fff;
        border: 1px solid #e4e4e4;
        width: 30px;
        height: 30px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        left: 80px;

        svg {
          fill: #4f576a;
        }

        &:hover svg {
          fill: $secondary-color;
          transition: all 0.2s ease-in;
        }
      }

      &__text {
        position: absolute;
        left: 130px;
        bottom: 0px;

        cursor: pointer;
        font-weight: bold;
        font-size: 8pt;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: $secondary-color;

        &:hover {
          color: $secondary-color-dark;
          transition: all 0.2s ease-in;
        }
      }

      @media (max-width: 600px) {
        margin: 0 1rem;
      }
    }
  }
}

.icon_check {
  fill: $green;
  margin-left: 10px;
}

.icon_info {
  fill: $red;
  margin-left: 10px;
}

.external {
  &__link {
    color: $primary-color-darker;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: $primary-color;
      transition: all 0.2s ease-in;
    }
  }
}
