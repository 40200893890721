.panel {
  &__deposit {
    padding: 32px;

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      padding: 32px 16px;
    }

    &__title {
      font-size: 28px;
      color: #66696F;
      font-weight: bold;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px;
      color: #66696F;
    }
  }

  &__modal {
    &__close {
      @media (max-width: 430px) {
        margin-top: 40px;
      }

      @media (max-width: 320px) {
        margin-top: 0px;
      }

      &__button {
        border: none;
        margin: 30px 35px 0px 0px;
        background: #fff;
        font-weight: bold;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      padding: 0px 80px;
      margin-bottom: 10px;
      margin-top: 50px;

      @media (max-width: 375px) {
        padding: 0px 60px;
      }
    }

    &__text {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;
      padding: 0px 120px;

      @media (max-width: 420px) {
        padding: 0px 110px;
      }

      @media (max-width: 375px) {
        padding: 0px 100px;
      }

      @media (max-width: 320px) {
        padding: 0px 70px;
      }
    }

    &__image {
      margin-left: 65px;
      margin-bottom: 25px;

      @media (max-width: 420px) {
        margin-bottom: 25px;
        margin-left: 40px;
      }

      @media (max-width: 375px) {
        margin-bottom: 25px;
        margin-left: 25px;
      }

      @media (max-width: 320px) {
        margin-bottom: 25px;
        margin-left: 10px;
      }
    }

    &__description {
      &__title {
        margin-left: 10px;
        color: $secondary-color;

        @media (max-width: 420px) {
          font-size: 13px;
        }

        @media (max-width: 320px) {
          font-size: 12px;
        }
      }

      &__subtitle {
        margin-left: 5px;

        @media (max-width: 420px) {
          font-size: 13px;
        }

        @media (max-width: 320px) {
          font-size: 12px;
        }
      }
    }

    &__continue {
      &__button {
        margin-top: 30px;
        margin-bottom: 10px;
        border-radius: 5px;
        width: 125px;
        height: 50px;
        background: #4197e3;
        color: white;
        border: none;
        font-weight: 500;
      }
    }
  }
}