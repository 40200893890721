.wallet {
    display: flex;
    flex-direction: column;
    width: 100%;
    // flex: 1;

    padding: 32px;
    scroll-behavior: smooth;

    .swiper-wrapper {
        width: 100% !important;
    }

    &__section {
        width: 100%;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                background: transparent;
                border: none;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: $text-dark-gray;
                text-align: right;

                cursor: pointer;

                &:hover {
                    color: $primary-hover;
                }
            }

            h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                color: $text-dark-gray;
                margin: 0;
            }
        }

        &:last-child {
            margin-top: 24px;
        }

        &__carousel {
            width: 100%;

            margin-top: 16px;

            &__card {
                width: 100%;
                max-width: 236px;
            }
        }

        &__accounts {
            margin-top: 16px;
            background: $background-secondary;
            padding: 32px;
            border-radius: 8px;
        }

        &__content {
            display: none;
        }
    }

    &__empty-credit-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $white3;
        border-radius: 8px;
        max-width: 230px;
        padding: 24px;
        margin-top: 16px;

        &__circle {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $background;
            border-radius: 50%;
            width: 48px;
            height: 48px;

            svg {
                color: $primary;
            }
        }

        &__text {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: $text-gray;
            margin-top: 16px;
        }

        @media (max-width:600px) {
            display: none;
        }
    }

    &__empty-state {
        display: flex;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid $white2;
        padding: 24px;
        max-width: 476px;
        width: 100%;
        margin-top: 16px;

        &__content {
            margin-left: 32px;

            h3 {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: $text-dark-gray;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: $text-gray;
            }

            button {
                background: transparent;
                border: none;
                text-align: left;
                padding: 0;
                display: flex;
                align-items: center;

                color: $primary;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: $primary-hover;
                }
            }
        }

        @media (max-width:600px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: none;

            img {
                margin-bottom: 8px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0;

                h3 {
                    display: none;
                }

                button {
                    text-align: center;
                    border-radius: 8px;
                    padding: 8px;
                    border: 1px solid $primary;

                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .mobile {
        display: none;
    }


    @media (max-width: 600px) {
        .web {
            display: none;
        }

        .mobile {
            display: flex;
        }

        &__section {
            &__header {
                button {
                    color: $primary;
                }
            }

            &__carousel {
                display: none;
            }

            &__content {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
                background: $background-secondary;
            }
        }
    }
}