.cashout_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    max-width: 820px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 24px;
  }

  &__header {
    margin-top: 32px;

    &__title {
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      color: $dark-gray;
    }

    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $light-gray;
    }
  }

  &__submit_button {
    padding: 12px 16px;
    background: $primary;
    color: $white;
    border-radius: 32px;
    outline: none;
    border: none;
    align-self: center;
    margin-top: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 6px rgba(11, 47, 99, 0.08);
    border-radius: 8px;
    padding: 32px;
    width: 100%;
    align-self: center;

    @media screen and (max-width: 500px) {
      align-items: center;
    }

    &__title {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: $dark-gray;
    }

    &__description {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $dark-gray;

      b {
        color: $tertiary;
      }
    }

    &__cashout_form {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__input {
        display: flex;
        width: 100%;
        max-width: 320px;
        height: 60px;
        box-shadow: 2px 2px 12px rgba(11, 47, 99, 0.16);
        border: none;
        border-radius: 32px;
        outline: none;
        padding: 0 16px;

        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: $primary;
      }

      &__shortcuts {
        margin-top: 16px;
        display: flex;
        gap: 12px;

        &__button {
          padding: 8px 16px;
          border: 1px solid $primary;
          border-radius: 32px;
          background: transparent;
          outline: none;

          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $primary;
          cursor: pointer;

          &:hover {
            border-color: $primary-hover;
            color: $primary-hover;
          }
        }
      }
    }

    &__button {
      padding: 8px 16px;
      background: $primary;
      color: $white;
      border-radius: 32px;
      outline: none;
      border: none;
      align-self: center;
      margin-top: 48px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;

      &:disabled {
        background: $light-gray;
        cursor: not-allowed;
      }
    }
  }

  &__details {
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      color: $dark-gray;
      font-weight: 600;
    }
  }
}

.account-list {
  width: 100%;
  margin-bottom: 32px;

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__add {
    display: flex;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid $border;
    margin-top: 16px;
    align-items: center;

    cursor: pointer;

    &__icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid $border;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0;
      margin: 0;

      i {
        color: $text-gray;
      }
    }

    p {
      border: none;
      background: transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $text-gray;
      margin: 0;
      margin-left: 16px;
    }

    &:hover {
      border-color: $primary;

      div {
        border-color: $primary;

        i {
          color: $primary;
        }
      }

      p {
        color: $primary;
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 24px;
    margin-top: 24px;
  }

  @media (max-width:600px) {
    &__add {
      flex-direction: column;
      align-items: center;
      row-gap: 24px;

      &__icon {
        width: 48px;
        height: 48px;

        i {
          font-size: 28px;
          color: $primary;
        }
      }
    }

    &__footer {
      flex-direction: column-reverse;
      row-gap: 24px;
      column-gap: 0;
    }
  }
}