.account {
  &__item {
    &__wrapper {
      margin-bottom: 15px;
      padding: 10px 0;
    }

    &__name {
      display: block;
      color: #313234;
      font-weight: bold;
      padding-bottom: 4px;
    }

    &__bank {
      display: block;
      font-size: 11pt;
      color: #66696F;
    }

    &__agency {
      display: block;
      font-size: 11pt;
      color: #66696F;
    }

    &__number {
      display: block;
      font-size: 11pt;
      color: #66696F;
    }

    &__validation {
      display: block;
      font-size: 11pt;
      
      &__invalid {
        color:#F40000
      }
      &__valid {
        color: #59AC7C
      }
      &__waiting {
        color: #EC660B
      }
    }

    &__button {
      padding: 10px 30px;
      color: $secondary-color;
      text-transform: uppercase;
      border: none;
      background: #fff;
      cursor: pointer;
      font-size: 9pt;
      letter-spacing: 1px;
      font-weight: bold;

      @media (max-width: 600px) {
        padding: 0;
        padding-top: 5px;
      }

      &:hover {
        color: $secondary-color-dark;
        transition: all 0.2s ease-in;
      }
    }
  }
}

.empty {
  &__list {
    color: $line-gray;
  }
}
