// profile-photo

.sidebar {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border: 1px solid #EBECED;
  width: 230px;

  svg {
    fill: none !important;
  }

  a:last-child {
    margin-bottom: 0;
  }

  &__menu {
    padding: 24px;
    margin: 0;

    &__final {
      color: #313234;
      font-size: 16px;
      font-weight: 700;
      margin-right: 11px;
    }

    &__last {
      padding: 1vw;

      &__link {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: #313234;
      }
    }

    &__item {
      list-style: none !important;

      &__title {
        font-weight: bold;
        font-size: 10pt;
        letter-spacing: 3px;
        text-transform: uppercase;
        padding: 20px 15px 15px;

        @media all and (min-width: 1100px) and (max-width: 1920px) {
          padding: 15px 15px 5px;
        }

        @media (max-width: 600px) {
          padding: 10px 15px 10px;
        }
      }

      &__link {
        padding: 9px 8px 9px 16px;
        display: block;
        color: $dark-gray;
        border-radius: 40px;
        margin-bottom: 12px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          color: $secondary;
        }

        &:hover {
          text-decoration: none;
          color: $primary-hover;
          transition: all 0.2s ease-in;
        }

        &--active {
          // display: block;
          background-color: #F9F1FF;
          color: $primary-hover;
          font-weight: bold;
        }

        &__icon {
          width: 24px;
          margin-right: 8px;

          svg {
            color: $secondary;
          }
        }

        // &__name {
        //   position: relative;
        //   top: 3px;
        // }

        @media all and (max-width: 1440px) {
          padding: 9px 8px 9px 16px;
        }
      }
    }
  }

  &__icon {
    position: relative;
    margin-top: 20px;
    left: 15px;
  }
}