.extract-download { 

    &__option {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }

}