// page not found

.access-denied {

  &__icon {
    padding: 60px 0 30px;
  }

  &__title {
    font-size: 35pt;
    font-weight: bold;
    margin-top: 5px;
  }

  &__description {
    font-size: 11pt;
  }
}